import React, { useEffect, useState, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import FormatDate from "../../utils/formatDateTime";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import _ from "lodash";
import { searchAllEdrs } from "../../public/endpoins";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import {
  getDoctorSpecialtyCall,
  getOnChangeSpecialityDoctorCall,
  getOnChangeSpecialityNurseCall,
  getAnesthesiologistsCall,
  getNurseSpecialityCall,
  labServiceSearchCall,
  saveLabRequestCall,
  editLabRequestCall,
  radServiceSearchCall,
  saveRadRequestCall,
  editRadRequestCall,
  saveDoctorNotesCall,
  editDoctorNotesCall,
  saveEDNurseCall,
  getEDNurseCall,
  editEDNurseCall,
  saveAnesthesiologistCall,
  getSelectedAnesthesiologistCall,
  editAnesthesiologistCall,
  saveConsultationNotesCall,
  getSelectedConsultationNoteCall,
  editConsultationNotesCall,
  completeConsultationNote,
  getLabTechniciansCall,
  getRadTechniciansCall,
} from "./NetworkCallForNotesScreen";
import { selectedDataCalled } from "./selectedData";
import {
  priorityArray,
  statusArray,
  tableHeadingForEDDoctorNotes,
  tableDataKeysForEDDoctorNotes,
  tableHeadingForNurse,
  tableDataKeysForNurse,
  tableHeadingForConsultationNotes,
  tableDataKeysForConsultationNotes,
  tableForSensei,
  keyForSensei,
  tableHeadingForAnesthesiologist,
  tableDataKeysForAnesthesiologist,
  tableHeadingForPharma,
  tableDataKeysForPharma,
  tableHeadingForLab,
  tableDataKeysForLab,
  tableHeadingForLabRad,
  tableDataKeysForLabRad,
} from "./configForNotesScreen";
import { audioURL, getIcd, getSingleEdr, updateLifeLimbEdr, addPharmacyRequest } from "../../public/endpoins";
import cookie from "react-cookies";
import Header from "../../components/Header/Header";
import AnesthIcon from "../../assets/img/Anesthesiologists.png";
import AssessIcon from "../../assets/img/ED Doctor Notes.png";
import EDNurseIcon from "../../assets/img/ED Nurse Notes.png";
import EOUNurseIcon from "../../assets/img/EOU Nurse Notes.png";
import NurseTechIcon from "../../assets/img/Nurse Technician Notes.png";
import PharmaIcon from "../../assets/img/Pharma.png";
import LabIcon from "../../assets/img/Lab.png";
import RadIcon from "../../assets/img/Radiology Notes.png";
import ConsultIcon from "../../assets/img/Consultation_Notes.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/TextInputStyle.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CustomTable from "../../components/Table/Table";
import plus_icon from "../../assets/img/Plus.png";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Notification from "../../components/Snackbar/Notification.js";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import ViewSingleRequest from "../../components/ViewRequest/ViewRequest";
import DoctorNotesDialog from "./DoctorNotesDialog";
import RequestsDialog from "./RequestsDialog";
// import LabRequestsDialog from "./LabRequestsDialog";
import RadRequestsDialog from "./RadRequestsDialog";
import Loader from "react-loader-spinner";
import eye_icon from "../../assets/img/Eye.png";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import PatientDetails2 from "../../components/PatientDetails/PatientDetailsDirect";
import ConfirmationDialog from "../../components/confirmationDialog/confirmationDialog";
import { connect } from "react-redux";
import {
  funForReducer,
  setPatientDetailsForReducer,
} from "../../actions/Checking";

import { setSelectedPatientForReducer } from "../../actions/patientDetails";
import classNames from "classnames";

const actions = { view: true, edit: true };
const action = { edit: true };
const action2 = { view: false, edit: false };

const useStylesForTabs = makeStyles({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
});

const consultantTypeArray = [
  {
    key: "Internal",
    value: "Internal",
  },
  {
    key: "External",
    value: "External",
  },
];

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
function EpisodeInfoDetailPage(props) {
  const matches = useMediaQuery("(min-width:600px)");

  const classes = useStylesForInput();

  const initialState = {
    labRequestArray: "",
    labComments: "",
    labPriority: "",
    editComments: "",
    editPriority: "",
    serviceName: "",
    labServiceName: "",
    labTechniciansList: "",
    labTechnician: "",

    radRequestArray: "",
    radServiceName: "",
    radComments: "",
    radPriority: "",
    radTechniciansList: "",
    radTechnician: "",

    doctorNotesArray: "",
    consultationNo: "",
    date: new Date(),
    description: "",
    consultationNotes: "",
    doctorconsultationNotes: "",

    consultant: "",
    consultationType: "",
    consultantArray: [],

    consultationNotesArray: "",
    consultationNotes: "",
    consultantNotes: "",
    consultantVoiceNotes: "",
    consultationSpeciality: "",

    requestDescription: "",
    requestStatus: "",

    residentNoteArray: "",
    doctorNotes: "",
    doctorNotesAdditional: "",
    note: "",
    doctor: cookie.load("current_user").name,

    anesthesiologist: "",
    anesthesiologistList: [],
    anesthesiologistArray: "",
    anesthesiologistNotes: "",

    edNurseSpeciality: "",
    edNurseSpecialityList: [],
    edNurse: "",
    edNurseList: [],
    edNurseArray: "",
    edNurseDescription: "",
    eouNurseArray: "",
    nurseTechnicianArray: "",

    pharmacyRequestArray: "",
    nonpharmacyRequestArray: "",
    requestType: "",
    section: "",
    code: [],
    patientId: "",
    diagnosisArray: "",
    medicationArray: "",
    careStreamArray: "",
    chiefComplaintArray: "",

    price: "",

    isBlocked: false,
    isRecording: false,

    isRecordingForConsultant: false,

    blobURL: "",
    fileAudio: null,
    fileAudioForConsultant: null,
    audioUrl: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    labRequestArray,
    labComments,
    labPriority,
    labServiceName,
    labTechniciansList,
    labTechnician,

    radRequestArray,
    radComments,
    radPriority,
    radServiceName,
    radTechniciansList,
    radTechnician,

    editComments,
    editPriority,
    serviceName,

    pharmacyRequestArray,
    nonpharmacyRequestArray,
    code,
    section,
    diagnosisArray,
    medicationArray,
    careStreamArray,
    chiefComplaintArray,

    doctorNotesArray,
    doctorNotes,
    doctorNotesAdditional,

    anesthesiologist,
    anesthesiologistList,
    anesthesiologistArray,

    edNurseSpeciality,
    edNurseSpecialityList,
    edNurse,
    edNurseList,
    edNurseArray,

    eouNurseArray,

    nurseTechnicianArray,

    consultationNotesArray,
    consultationNotes,
    consultantNotes,
    consultantVoiceNotes,
    consultationSpeciality,
    requestDescription,
    requestStatus,

    consultant,
    consultationType,
    consultantArray,

    isBlocked,
    isRecording,
    isRecordingForConsultant,
    blobURL,
    fileAudio,
    fileAudioForConsultant,
  } = state;

  const onChangeValue = (e) => {
    if (e.target.name === "consultationSpeciality") {
      dispatch({
        field: e.target.name,
        value: e.target.value,
      });
      getOnChangeSpecialityDoctorCall(dispatch, e, currentUser._id);
    } else if (e.target.name === "edNurseSpeciality") {
      dispatch({
        field: e.target.name,
        value: e.target.value,
      });
      getOnChangeSpecialityNurseCall(value, dispatch, e, currentUser._id);
    } else if (e.target.name === "consultationType") {
      dispatch({
        field: e.target.name,
        value: e.target.value,
      });
      setEnableFields(true);
    }
    {
      dispatch({
        field: e.target.name,
        value: e.target.value.replace(/[^\w\s]/gi, ""),
      });
    }
  }; 

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [value, setValue] = useState(0);
  const [patientDetails, setPatientDetails] = useState("");
  const [openItemDialog, setOpenItemDialog] = useState(false);
  const [openRequestDialog, setOpenRequestDialog] = useState(false);
  const [openAddDoctorNoteDialog, setOpenAddDoctorNoteDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [userCameFrom, setUserCameFrom] = useState("patientlist");
  const [selectedRecord, setSelectedRecord] = useState({});
  const [lifeLimbOn, setLifeLimbOn] = useState(false);
  const [dischargeInitiated, setDischargeInitiated] = useState(false);
  const [defaultRADCheckList, setDefaultRADCheckList] = useState({});

  // Notes
  const [openRadDialog, setOpenRadDialog] = useState(false);

  const [item, setItem] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [itemFound, setItemFound] = useState("");
  const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [doctorForm, setDoctorForm] = useState(false);
  const [dialogForm, setDialogForm] = useState(false);

  const [labForm, setLabForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [radForm, setRadForm] = useState(false);
  const [enableFields, setEnableFields] = useState(false);

  const [id, setId] = useState("");
  const [searchRadioQuery, setSearchRadioQuery] = useState("");
  const [radioItemFoundSuccessfull, setRadioItemFoundSuccessfully] = useState(
    ""
  );

  const [radioItemFound, setRadioItemFound] = useState("");
  const [, setIsLoading] = useState(true);
  const [timer, setTimer] = useState(null);
  const [selectedNoteToEdit, setSelectedNoteToEdit] = useState("");
  const [viewRecord, setViewRecord] = useState(false);
  const [selectedEDNurseToEdit, setSelectedEDNurseToEdit] = useState("");

  const [selectedConsultationToEdit, setSelectedConsultationToEdit] = useState(
    ""
  );
  const [anesthesiologistToEdit, setSelectedAnesthesiologistToEdit] = useState(
    ""
  );
  const [selectedLabRequestToEdit, setSelectedLabRequestToEdit] = useState("");
  const [selectedRadRequestToEdit, setSelectedRadRequestToEdit] = useState("");
  const [loadSearchedData, setLoadSearchedData] = useState(false);
  const [selectedLab, setSelectedLab] = useState("");
  const [selectedRad, setSelectedRad] = useState("");
  const [consultationSpecialityList, setConsultationSpecialityList] = useState(
    []
  );
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [selectedAdditionalCodes, setSelectedAdditionalCodes] = useState([]);
  const [reponseThroughNotification, setReponseThroughNotification] = useState(
    ""
  );

  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
  // var allCareStreams = [];
  
  // React
  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ pageId, setPageId ] = useState(props.match.params.pagename);

  function loadPatientDetails()
  {

    // Getting Realtime Data from Screen
    axios
      .get(getSingleEdr + "/" + episodeId)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);

          setSelectedRecord(remoteRecord);
          setLifeLimbOn(remoteRecord.lifelimb ? true : false);

          // setDischargeInitiated(remoteRecord.dischargeRequest.dischargeSummary ? true : false)
          setDischargeInitiated(remoteRecord.status==="Discharging");

          setDefaultRADCheckList(remoteRecord.defaultRADCheckList ? remoteRecord.defaultRADCheckList : {
            pregnantCheck: 'no',
            breastFeeding: 'no',
            highInfectionRisk: 'no',
            highInfectionRiskDesc: '',
            patientDiabetic: 'no',
            diabetesControlled: '',
            patientAllergies: 'no',
            patientAllergiesDesc: null,
            pacemaker: 'no',
            cerebralAneurysmClip: 'no',
            cochlearImplants: 'no',
            neuroStimulators: 'no',
            programmableHydrocephalusShunt: 'no',
            metallicForeignBodyInEye: 'no',
            otherMetallicImplants: 'no',
            otherMetallicImplantsDesc: '',
            assistantRequired: 'no'
          });

          const object = {
            '_id': remoteRecord._id,
            patientName:
              remoteRecord.patientId.name[0].given[0] +
              " " +
              remoteRecord.patientId.name[0].family,
            mrn: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0
                      ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc
                      : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          console.log('handleView::', object)
          setPatientDetails(object);


          if (pageId === "consultation"){
            setValue(4);
          } else if (pageId === "pharmacy"){
            setValue(5);
          } else if (pageId === "non-pharmacy"){
            setValue(6);
          } else if (pageId === "lab"){
            setValue(7);
          } else if (pageId === "rad"){
            setValue(8);
          }

          selectedDataCalled(remoteRecord, dispatch);

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
  }


  useEffect(() => {
    console.log("props", props);

    console.log('DEBUG::', 'edrId::', episodeId);
    console.log('DEBUG::', 'params::', props.match.params);
    console.log('DEBUG::', getSingleEdr + "/" + episodeId);


    loadPatientDetails();

    getDoctorSpecialtyFunc();
    getAnesthesiologists();
    getNurseSpeciality();
    getLabTechnicians();
    getRadTechnicians();

  }, []);

  useEffect(() => {
    console.log("DoctorNotesDialog:: props:: output::", selectedCodes);
  }, [selectedCodes]);

  function getDoctorSpecialtyFunc() {
    getDoctorSpecialtyCall(
      setConsultationSpecialityList,
      dispatch,
      setOpenNotification,
      setErrorMsg
    );
  }
  
  /*
  useEffect(() => {
    if (props.selectedPatientDetails) {
      let copiedObj = JSON.parse(JSON.stringify(props.selectedPatientDetails));
      selectedDataCalled(copiedObj, dispatch);
    }
  }, [props.selectedPatientDetails]);
  */

  function getAnesthesiologists() {
    getAnesthesiologistsCall(dispatch, setOpenNotification, setErrorMsg);
  }

  function getLabTechnicians() {
    getLabTechniciansCall(
      currentUser._id,
      dispatch,
      setOpenNotification,
      setErrorMsg
    );
  }

  function getRadTechnicians() {
    getRadTechniciansCall(
      currentUser._id,
      dispatch,
      setOpenNotification,
      setErrorMsg
    );
  }

  function getNurseSpeciality() {
    getNurseSpecialityCall(dispatch, setOpenNotification, setErrorMsg);
  }

  const openDCDHistoryRequest = () => {
    let newURL = "/dashboard/home/"+episodeId+"/dcd-history/";
    props.history.push({
      pathname: newURL,
      state: {
        // 'lifelimbStatus': lifeLimbOn
      }
    });
  }

  const openNewLabRequest = () => {
    let newURL = "/dashboard/home/"+episodeId+"/lab/new";
    /*if (selectedRecord && 
      selectedRecord.dcdForm[0].triageAssessment.length>0 &&
      selectedRecord.dcdForm[0].pastMedicalHistory.length>0 &&
      selectedRecord.dcdForm[0].patientDetails.length>0
    ) {*/
    props.history.push({
      pathname: newURL,
      state: {
        'lifelimbStatus': lifeLimbOn
      }
    });
    /*}else{
      setOpenNotification(true);
      setErrorMsg("Please complete the DCD Form First.");
    }*/
  }

  const openNewRadRequest = () => {
    let newURL = "/dashboard/home/"+episodeId+"/rad/new";

    /*if (selectedRecord && 
      selectedRecord.dcdForm[0].triageAssessment.length>0 &&
      selectedRecord.dcdForm[0].pastMedicalHistory.length>0 &&
      selectedRecord.dcdForm[0].patientDetails.length>0
    ) {*/
      props.history.push({
        pathname: newURL,
        state: {
          'gender': selectedRecord.patientId.gender,
          'lifelimbStatus': lifeLimbOn,
          'defaultRADCheckList': defaultRADCheckList,
        }
      });
    /*}else{
      setOpenNotification(true);
      setErrorMsg("Please complete the DCD Form First.");
    }*/
  }

  const handleTabChange = (event, newValue) => {
    let newURL = "";
    if (newValue===0) {
      newURL = "/dashboard/home/"+episodeId+"/notes";
    } else if (newValue===4){
      newURL = "/dashboard/home/"+episodeId+"/consultation";
    } else if (newValue===5){
      newURL = "/dashboard/home/"+episodeId+"/pharmacy";
    } else if (newValue===6){
      newURL = "/dashboard/home/"+episodeId+"/non-pharmacy";
    } else if (newValue===7){
      newURL = "/dashboard/home/"+episodeId+"/lab";
    } else if (newValue===8){
      newURL = "/dashboard/home/"+episodeId+"/rad";
    }
    console.log('DEBUG::', 'newValue', newValue, 'newValueEqual', (newValue===7), 'newURL', newURL);

    if (newURL !== ""){
      props.history.push({
        pathname: newURL,
      });
    }

    setValue(newValue);
  };

  function viewItem(item) {
    if (item !== "") {
      setOpenItemDialog(true);
      setItem(item);
    } else {
      setOpenItemDialog(false);
      setItem("");
    }
  }

  function hideDialog() {
    setOpenUpdateStatusModal(false);
    setViewRecord(false);
    setOpenAddDoctorNoteDialog(false);
    setOpenDialog(false);
    setOpenRequestDialog(false);
    setSelectedNoteToEdit("");
    // setSelectedCodes([]);
    // setIcdArr([]);
    setSelectedEDNurseToEdit("");
    setSelectedAnesthesiologistToEdit("");
    setSelectedConsultationToEdit("");

    dispatch({ field: "doctorNotes", value: "" });
    dispatch({ field: "doctorNotesAdditional", value: "" });
    dispatch({ field: "section", value: "" });
    dispatch({ field: "blobURL", value: "" });
    dispatch({ field: "fileAudio", value: "" });
    dispatch({ field: "consultantNotes", value: "" });
    dispatch({ field: "fileAudioForConsultant", value: "" });
    dispatch({ field: "consultationNotes", value: "" });
    dispatch({ field: "consultationSpeciality", value: "" });
    dispatch({ field: "consultant", value: "" });
    dispatch({ field: "consultationType", value: "" });

    dispatch({ field: "anesthesiologistNotes", value: "" });
    dispatch({ field: "anesthesiologist", value: "" });
    dispatch({ field: "requestDescription", value: "" });
    dispatch({ field: "requestStatus", value: "" });
    dispatch({ field: "edNurseSpeciality", value: "" });
    dispatch({ field: "edNurse", value: "" });
  }

  const triggerLabChange = (a) => {
    handleLabServiceSearch(a);
  };

  const handlePauseLabSearch = (e) => {
    setLoadSearchedData(true);
    clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        triggerLabChange(a);
      }, 600)
    );
  };

  const handleLabServiceSearch = (e) => {
    labServiceSearchCall(
      e,
      setItemFoundSuccessfully,
      setItemFound,
      setLoadSearchedData
    );
  };

  function handleAddItem(i) {
    console.log("selected item", i);

    setSelectedLab(i);

    dispatch({
      field: "labServiceName",
      value: i.name,
    });

    // dispatch({
    //   field: 'labRequestArray',
    //   value: [
    //     ...labRequestArray,
    //     {
    //       name: i.name,
    //       requester: currentUser.subType[0],
    //       status: 'pending',
    //       labServiceName: i.labServiceName,
    //     },
    //   ],
    // })

    // }

    setSearchQuery("");
    // setaddLabRequest(true)
  }

  function validateFields() {
    return (
      labComments &&
      labComments.length > 0 &&
      labPriority &&
      labPriority.length > 0 &&
      selectedLab
    );
  }

  const addSelectedLabItem = () => {
    if (validateFields()) {
      const params = {
        serviceId: selectedLab._id,
        name: selectedLab.name,
        type: selectedLab.type,
        price: selectedLab.price,
        priority: labPriority,
        staffId: currentUser._id,
        edrId:
          props.history.location.state.comingFrom === "notification"
            ? reponseThroughNotification._id
            : selectedRecord._id,
        notes: labComments,
        labTechnicianId: labTechnician,
      };
      console.log("Lab params", params);
      saveLabRequestCall(
        params,
        setOpenNotification,
        setErrorMsg,
        props,
        dispatch
      );
    } else {
      setOpenNotification(true);
      setErrorMsg("Error while adding the Lab Request");
    }
    setLabForm(true);
  };

  const EditLabRequest = (rec) => {
    console.log("single lab request", rec);
    setSelectedLabRequestToEdit(rec);
    if (rec.serviceCode) {
      setOpenRequestDialog(true);
    } else {
      setOpenNotification(true);
      setErrorMsg("Please save lab request before");
    }

    dispatch({ field: "serviceName", value: rec.name });
    dispatch({ field: "editPriority", value: rec.priority });
    dispatch({ field: "editComments", value: rec.notes });
  };

  const handleEditLabRequest = () => {
    if (currentUser._id !== selectedLabRequestToEdit.requestedBy._id) {
      setOpenRequestDialog(false);
      setErrorMsg("You can only update the request which is added by you.");
      setOpenNotification(true);
      return;
    }

    if (
      selectedLabRequestToEdit.status === "completed" ||
      selectedLabRequestToEdit.status === "complete"
    ) {
      setOpenRequestDialog(false);
      setOpenNotification(true);
      setErrorMsg("Request can not be updated once completed.");
      return;
    }

    if (validateEditFields()) {
      const params = {
        labId: selectedLabRequestToEdit._id,
        priority: editPriority,
        edrId:
          props.history.location.state.comingFrom === "notification"
            ? reponseThroughNotification._id
            : selectedRecord._id,
        notes: editComments,
        // labTechnicianId: labTechnician,
      };
      console.log("Lab params", params);
      editLabRequestCall(
        params,
        setOpenRequestDialog,
        setOpenNotification,
        setErrorMsg,
        props,
        dispatch
      );
    } else {
      setOpenNotification(true);
      setErrorMsg("Error while updating the Lab Request");
    }
    setEditForm(true);
  };

  const triggerRadioChange = (a) => {
    handleRadioSearch(a);
  };

  const handleRadioPauseSearch = (e) => {
    setLoadSearchedData(true);
    clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchRadioQuery(a);

    setTimer(
      setTimeout(() => {
        triggerRadioChange(a);
      }, 600)
    );
  };

  const handleRadioSearch = (e) => {
    radServiceSearchCall(
      e,
      setRadioItemFoundSuccessfully,
      setRadioItemFound,
      setLoadSearchedData
    );
  };

  function handleAddRadioItem(i) {
    console.log("selected item", i);

    setSelectedRad(i);

    dispatch({
      field: "radServiceName",
      value: i.name,
    });

    // dispatch({
    //   field: 'radRequestArray',
    //   value: [
    //     ...radRequestArray,
    //     {
    //       name: i.name,
    //       requester: currentUser.staffType,
    //       status: 'pending',
    //     },
    //   ],
    // })

    // }

    setSearchRadioQuery("");
    // setaddLabRequest(true)
  }

  function validateRadFields() {
    return (
      radComments &&
      radComments.length > 0 &&
      radPriority &&
      radPriority.length > 0 &&
      selectedRad
    );
  }

  const addSelectedRadioItem = () => {
    if (validateRadFields()) {
      const params = {
        serviceId: selectedRad._id,
        name: selectedRad.name,
        type: selectedRad.type,
        price: selectedRad.price,
        priority: radPriority,
        staffId: currentUser._id,
        edrId:
          props.history.location.state.comingFrom === "notification"
            ? reponseThroughNotification._id
            : selectedRecord._id,
        notes: radComments,
        radTechnicianId: radTechnician,
      };
      console.log("Rad params", params);
      saveRadRequestCall(
        params,
        setOpenNotification,
        setErrorMsg,
        props,
        dispatch
      );
    } else {
      setOpenNotification(true);
      setErrorMsg("Error while adding the Rad Request");
    }
    setRadForm(true);
  };

  const EditRadRequest = (rec) => {
    console.log("single rad request", rec);
    setSelectedRadRequestToEdit(rec);
    if (rec.serviceCode) {
      setOpenRequestDialog(true);
    } else {
      setOpenNotification(true);
      setErrorMsg("Please save rad request before");
    }

    dispatch({ field: "serviceName", value: rec.name });
    dispatch({ field: "editPriority", value: rec.priority });
    dispatch({ field: "editComments", value: rec.notes });
  };

  const viewPharmacyRequest = (item, type) => {
    console.log("viewPharmacyRequest", item);
    let pathname = "/dashboard/home/"+episodeId+"/"+type+"/"+item._id+"/details";

    props.history.push({
      pathname: pathname,
    });
  };

  const viewLabReport = (item) => {
    console.log("labReport", item);
    // let pathname = "/dashboard/home/viewLaboratoryReport";
    let pathname = "/dashboard/home/"+episodeId+"/lab/"+item.requestId+"/details";
    // let labRadStatus = item.status;

    props.history.push({
      pathname: pathname,
      // state: {
      //   selectedItem: {
      //     labRadStatus,
      //     ...item,
      //     ...selectedRecord,
      //   }
      // },
    });
  };

  const viewRadReport = (item) => {
    console.log("radReport", item);
    let pathname = "/dashboard/home/"+episodeId+"/rad/"+item.requestId+"/details";

    props.history.push({
      pathname: pathname,
    });
  };

  const viewLabRadReportDetail = (item) => {
    console.log("item", item);
    let pathname;
    if (value === 7) {
      pathname = "/dashboard/home/laboratoryReports";
    } else {
      pathname = "/dashboard/home/radiologyReports";
    }
    let labRadStatus = item.status;
    props.history.push({
      pathname: pathname,
      state: {
        selectedItem: {
          labRadStatus,
          ...item,
          ...selectedRecord,
        },
        comingFor: "view",
      },
    });
  };

  function validateEditFields() {
    return (
      editComments &&
      editComments.length > 0 &&
      editPriority &&
      editPriority.length > 0
    );
  }

  const handleEditRadRequest = () => {
    if (selectedRadRequestToEdit.status === "completed") {
      setOpenRequestDialog(false);
      setOpenNotification(true);
      setErrorMsg("Request can not be updated once completed.");
      return;
    }
    console.log(currentUser._id, "current User Id");
    console.log(selectedRadRequestToEdit.requestedBy._id, "Requester ID");

    if (selectedRadRequestToEdit.requestedBy._id !== currentUser._id) {
      setOpenRequestDialog(false);
      setOpenNotification(true);
      setErrorMsg("You can only edit request which is added by you.");
      return;
    }

    if (validateEditFields()) {
      const params = {
        radId: selectedRadRequestToEdit._id,
        priority: editPriority,
        edrId:
          props.history.location.state.comingFrom === "notification"
            ? reponseThroughNotification._id
            : selectedRecord._id,
        notes: editComments,
        // radTechnicianId: radTechnician,
      };
      console.log("Rad params", params);
      editRadRequestCall(
        params,
        setOpenRequestDialog,
        setOpenNotification,
        setErrorMsg,
        props,
        dispatch
      );
    } else {
      setOpenNotification(true);
      setErrorMsg("Error while adding the Rad Request");
    }
    setEditForm(true);
  };

  const TriageAssessment = () => {
    props.history.push({
      pathname: "/dashboard/home/triageassessment",
      state: selectedRecord,
      // state:
      //   props.history.location.state.comingFrom === "notification"
      //     ? reponseThroughNotification
      //     : selectedRecord,
    });
  };

  const BacktoInvestigationActionHandler = () => {
    console.log('handleView', 'BackActionHandler', selectedRecord.dcdForm);
    let data = selectedRecord.dcdForm[0] ? selectedRecord.dcdForm[0].investigation.length : 0;
    let dataAlreadyFilled = false;

    let path = "/dashboard/home/dcddashboard/Form";
    if (data > 0) {
      path = "/dashboard/home/dcddashboard/viewForm";
      dataAlreadyFilled = true;
    }

    console.log(path, data, dataAlreadyFilled);
    props.history.push({
      pathname: path,
      state: {
        comingFor: "Investigations",
        selectedItem: selectedRecord,
        dcdForm: selectedRecord.dcdForm,
        patientEdrForm: selectedRecord,
        careStream: "",
        dataAlreadyFilled,
      },
    });
    /*
    <Box
      handleClick={onBoxClick}
      title="Investigations"
      detail="Investigate the patient"
      numberCount="6"
      boxIcon={InvestigationsBoxIcon}
      backgroundColor={"#3893FC"}
      height1={matches ? 410 : 100}
      tickIcon={
        dcdForm[0] && dcdForm[0].investigation.length > 0
          ? true
          : false
      }
      dataCount={dcdForm[0] ? dcdForm[0].investigation.length : "0"}
    />
    */
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  const validateDoctorNotes = () => {
    return (doctorNotes && doctorNotes.length > 0) && ( selectedCodes && selectedCodes.length>0 );
  };

  const submitDoctorNotes = () => {
    if (validateDoctorNotes()) {
      const data = new FormData();
      data.append("file", fileAudio);

      let dCodes = [];
      let dCodes2 = [];
      Object.entries(selectedCodes).map((i,v) => {
        console.log(i[1]);
        dCodes.push(i[1].id);
      });

      Object.entries(selectedAdditionalCodes).map((i,v) => {
        console.log(i[1]);
        dCodes2.push(i[1].id);
      });

      const object = {
        addedBy: currentUser._id,
        edrId: episodeId,
        notes: doctorNotes,
        code: dCodes,
        additionalCode: dCodes2,
        additionalNotes: doctorNotesAdditional,
        section: section,
      };

      console.log("Params for Save::", object);
      data.append("data", JSON.stringify(object));

      if (isRecording) {
        dispatch({ field: "openNotification", value: true });
        setErrorMsg("Please record voice then submit.");
      } else {
        saveDoctorNotesCall(
          setOpenAddDoctorNoteDialog,
          data,
          props,
          dispatch,
          [], //setIcdArr
          loadPatientDetails
        );
      }
    } else {
      setOpenNotification(true);
      setErrorMsg("Please add doctor notes");
    }
    setDoctorForm(true);
  };

  const start = (comingFor) => {
    if (comingFor === "consultant") {
      if (isBlocked) {
        console.log("Permission Denied");
      } else {
        Mp3Recorder.start()
          .then(() => {
            dispatch({ field: "isRecordingForConsultant", value: true });
          })
          .catch((e) => console.error(e));
      }
    } else {
      if (isBlocked) {
        console.log("Permission Denied");
      } else {
        Mp3Recorder.start()
          .then(() => {
            dispatch({ field: "isRecording", value: true });
          })
          .catch((e) => console.error(e));
      }
    }
  };

  const stop = (comingFor) => {
    if (comingFor === "consultant") {
      console.log("sd");
      Mp3Recorder.stop()
        .getMp3()
        .then(([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob);
          console.log("blobURL", blobURL);
          dispatch({ field: "consultantVoiceNotes", value: blobURL });
          dispatch({ field: "isRecordingForConsultant", value: false });
          var file = new File([blob], "first", { type: "audio/mp3" });
          dispatch({ field: "fileAudioForConsultant", value: file });
        })
        .catch((e) => console.log(e));
    } else {
      Mp3Recorder.stop()
        .getMp3()
        .then(([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob);
          console.log("blobURL", blobURL);
          dispatch({ field: "blobURL", value: blobURL });
          dispatch({ field: "isRecording", value: false });
          var file = new File([blob], "first", { type: "audio/mp3" });
          dispatch({ field: "fileAudio", value: file });
        })
        .catch((e) => console.log(e));
    }
  };

  const editDoctorNotes = (rec) => {
    console.log("editDoctorNotes:: rec::", rec);
    setSelectedNoteToEdit(rec);
    const url = `${audioURL}/${rec.voiceNotes}`;
    dispatch({ field: "blobURL", value: url });
    dispatch({ field: "doctorNotes", value: rec.notes });
    dispatch({ field: "doctorNotesAdditional", value: rec.additionalNotes });
    dispatch({ field: "section", value: rec.section });
    dispatch({ field: "selectedCodes", value: rec.code });
    dispatch({ field: "selectedAdditionalCodes", value: rec.additionalCode });

    if (rec.code.length > 0){
      Object.entries(rec.code).map((i,v) => {
        if (i[1] && i[1]._id){
          rec.code[v].id = i[1]._id;
        }
      });
      setSelectedCodes(rec.code);
    }else{
      setSelectedCodes([]);
    }

    if (rec.additionalCode.length > 0){
      Object.entries(rec.additionalCode).map((i,v) => {
        // rec.additionalCode[v].id = i[1]._id;
        if (i[1] && i[1]._id){
          rec.additionalCode[v].id = i[1]._id;
        }
      });
      setSelectedAdditionalCodes(rec.additionalCode);
    }else{
      setSelectedAdditionalCodes([]);
    }

    setOpenAddDoctorNoteDialog(true);
  };

  const handleViewDoctorNotes = (rec) => {
    setViewRecord(true);
    setSelectedNoteToEdit(rec);
    const url = `${audioURL}/${rec.voiceNotes}`;
    dispatch({ field: "blobURL", value: url });
    dispatch({ field: "doctorNotes", value: rec.notes });
    dispatch({ field: "doctorNotesAdditional", value: rec.additionalNotes });
    dispatch({ field: "section", value: rec.section });
    // setSelectedCodes(rec.code);

    setOpenAddDoctorNoteDialog(true);
  };

  const handleEditDoctorNotes = () => {
    console.log("EDIT NOTES , ", doctorNotes, code, section);

    if (selectedNoteToEdit.addedBy._id !== currentUser._id) {
      setOpenAddDoctorNoteDialog(false);
      setOpenNotification(true);
      setErrorMsg("You can not modify notes which not added by you");
      return;
    }

    if (validateDoctorNotes()) {
      const data = new FormData();
      data.append("file", fileAudio);

      let dCodes  = [];
      let dCodes2 = [];
      Object.entries(selectedCodes).map((i,v) => {
        dCodes.push(i[1].id);
      });

      Object.entries(selectedAdditionalCodes).map((i,v) => {
        dCodes2.push(i[1].id);
      });

      let object;
      if (!fileAudio) {
        object = {
          addedBy: currentUser._id,
          edrId:
            props.history.location.state.comingFrom === "notification"
              ? reponseThroughNotification._id
              : selectedRecord._id,
          noteId: selectedNoteToEdit._id,
          notes: doctorNotes,
          additionalNotes: doctorNotesAdditional,
          voiceNotes: selectedNoteToEdit.voiceNotes,
          code: dCodes, //code.length > 0 ? code : selectedCodes,
          additionalCode: dCodes2, //code.length > 0 ? code : selectedCodes,
          section: section,
        };
      } else {
        object = {
          addedBy: currentUser._id,
          edrId:
            props.history.location.state.comingFrom === "notification"
              ? reponseThroughNotification._id
              : selectedRecord._id,
          noteId: selectedNoteToEdit._id,
          notes: doctorNotes,
          additionalNotes: doctorNotesAdditional,
          code: dCodes, //code.length > 0 ? code : selectedCodes,
          additionalCode: dCodes2, //code.length > 0 ? code : selectedCodes,
          section: section,
        };
      }
      console.log("params edit", object);
      data.append("data", JSON.stringify(object));

      if (isRecording) {
        dispatch({ field: "openNotification", value: true });
        setErrorMsg("Please record voice then submit.");
      } else {
        editDoctorNotesCall(
          setOpenAddDoctorNoteDialog,
          data,
          props,
          dispatch,
          [], //setIcdArr
        );
      }
    } else {
      setOpenNotification(true);
      setErrorMsg("Please add doctor notes");
    }
    setDoctorForm(true);
  };

  const validateEDNurse = () => {
    return (
      requestDescription &&
      requestDescription.length > 0 &&
      edNurseSpeciality &&
      edNurseSpeciality.length > 0 &&
      edNurse &&
      edNurse.length > 0
    );
  };

  const submitEDNurseRequest = () => {
    const data = new FormData();
    data.append("file", fileAudio);
    // if (validateEDNurse()) {
    let object;
    if (value === 1) {
      object = {
        addedBy: currentUser._id,
        edrId:
          props.history.location.state.comingFrom === "notification"
            ? reponseThroughNotification._id
            : selectedRecord._id,
        notes: requestDescription,
        edNurse: edNurse,
        speciality: edNurseSpeciality,
      };
    } else if (value === 2) {
      object = {
        addedBy: currentUser._id,
        edrId:
          props.history.location.state.comingFrom === "notification"
            ? reponseThroughNotification._id
            : selectedRecord._id,
        notes: requestDescription,
        eouNurse: edNurse,
        speciality: edNurseSpeciality,
      };
    } else if (value === 3) {
      object = {
        addedBy: currentUser._id,
        edrId:
          props.history.location.state.comingFrom === "notification"
            ? reponseThroughNotification._id
            : selectedRecord._id,
        notes: requestDescription,
        nurseTechnician: edNurse,
        speciality: edNurseSpeciality,
      };
    }

    console.log("id", object);
    data.append("data", JSON.stringify(object));
    console.log("data", data);

    if (isRecording) {
      dispatch({ field: "openNotification", value: true });
      setErrorMsg("Please record voice then submit.");
    } else {
      saveEDNurseCall(value, setOpenDialog, data, props, dispatch);
    }
    // } else {
    //   setOpenNotification(true)
    //   setErrorMsg('Please Fill the fields')
    // }
    // setDialogForm(true)
  };

  const handleViewEDnurse = (rec) => {
    setViewRecord(true);
    setSelectedEDNurseToEdit(rec);
    const url = `${audioURL}/${rec.voiceNotes}`;

    getEDNurseCall(value, rec, url, dispatch, setOpenDialog, currentUser._id);
  };

  const editEDNurse = (rec) => {
    console.log("reccc", rec);
    if (rec.status === "completed") {
      setOpenNotification(true);
      setErrorMsg("Cannot Edit a completed request");
    } else {
      setSelectedEDNurseToEdit(rec);
      const url = `${audioURL}/${rec.voiceNotes}`;

      getEDNurseCall(value, rec, url, dispatch, setOpenDialog, currentUser._id);
    }
  };

  console.log("anesthesiologistArray", anesthesiologistArray);

  const updateStatus = (rec) => {
    console.log("Record to update status : ", rec);
    if (rec.status === "completed") {
      setOpenNotification(true);
      setErrorMsg("Cannot Edit a completed request");
    } else {
      setSelectedEDNurseToEdit(rec);
      const url = `${audioURL}/${rec.voiceNotes}`;
      dispatch({ field: "blobURL", value: url });
      dispatch({ field: "requestDescription", value: rec.notes });
      dispatch({ field: "edNurseSpeciality", value: rec.speciality });
      dispatch({ field: "requestStatus", value: rec.status });
      if (value === 1) {
        dispatch({
          field: "edNurse",
          value: rec.edNurseId,
        });
      } else if (value === 2) {
        dispatch({
          field: "edNurse",
          value: rec.eouNurseId,
        });
      }
      setOpenUpdateStatusModal(true);
    }
  };

  const handleEditEDNurse = () => {
    if (
      selectedEDNurseToEdit.addedBy._id !== currentUser._id &&
      selectedEDNurseToEdit.edNurseId !== currentUser._id &&
      selectedEDNurseToEdit.eouNurseId !== currentUser._id
    ) {
      hideDialog();
      setOpenNotification(true);
      setErrorMsg("You can only update request which is added by you.");
    } else {
      const data = new FormData();
      data.append("file", fileAudio);

      if (validateEDNurse()) {
        let object;
        if (value === 1) {
          if (!fileAudio) {
            object = {
              addedBy: selectedEDNurseToEdit.addedBy._id,
              edrId:
                props.history.location.state.comingFrom === "notification"
                  ? reponseThroughNotification._id
                  : selectedRecord._id,
              noteId: selectedEDNurseToEdit._id,
              notes: requestDescription,
              edNurse: edNurse,
              speciality: edNurseSpeciality,
              voiceNotes: selectedEDNurseToEdit.voiceNotes,
              status: requestStatus,
            };
          } else {
            object = {
              addedBy: selectedEDNurseToEdit.addedBy._id,
              edrId:
                props.history.location.state.comingFrom === "notification"
                  ? reponseThroughNotification._id
                  : selectedRecord._id,
              noteId: selectedEDNurseToEdit._id,
              notes: requestDescription,
              edNurse: edNurse,
              speciality: edNurseSpeciality,
              status: requestStatus,
            };
          }
        } else if (value === 2) {
          if (!fileAudio) {
            object = {
              addedBy: currentUser._id,
              edrId:
                props.history.location.state.comingFrom === "notification"
                  ? reponseThroughNotification._id
                  : selectedRecord._id,
              noteId: selectedEDNurseToEdit._id,
              notes: requestDescription,
              eouNurse: edNurse,
              speciality: edNurseSpeciality,
              voiceNotes: selectedEDNurseToEdit.voiceNotes,
              status: requestStatus,
            };
          } else {
            object = {
              addedBy: currentUser._id,
              edrId:
                props.history.location.state.comingFrom === "notification"
                  ? reponseThroughNotification._id
                  : selectedRecord._id,
              noteId: selectedEDNurseToEdit._id,
              notes: requestDescription,
              eouNurse: edNurse,
              speciality: edNurseSpeciality,
              status: requestStatus,
            };
          }
        } else if (value === 3) {
          if (!fileAudio) {
            object = {
              addedBy: currentUser._id,
              edrId:
                props.history.location.state.comingFrom === "notification"
                  ? reponseThroughNotification._id
                  : selectedRecord._id,
              noteId: selectedEDNurseToEdit._id,
              notes: requestDescription,
              nurseTechnician: edNurse,
              speciality: edNurseSpeciality,
              voiceNotes: selectedEDNurseToEdit.voiceNotes,
            };
          } else {
            object = {
              addedBy: currentUser._id,
              edrId:
                props.history.location.state.comingFrom === "notification"
                  ? reponseThroughNotification._id
                  : selectedRecord._id,
              noteId: selectedEDNurseToEdit._id,
              notes: requestDescription,
              nurseTechnician: edNurse,
              speciality: edNurseSpeciality,
            };
          }
        }

        console.log("Params for edit : ", object);
        data.append("data", JSON.stringify(object));

        if (isRecording) {
          dispatch({ field: "openNotification", value: true });
          setErrorMsg("Please record voice then submit.");
        } else {
          editEDNurseCall(value, data, setOpenDialog, props, dispatch);
        }
      } else {
        setOpenNotification(true);
        setErrorMsg("Please update ed request");
      }
    }
    setDialogForm(true);
  };

  const validateAnesthesiologistNotes = () => {
    return (
      requestDescription &&
      requestDescription.length > 0 &&
      anesthesiologist &&
      anesthesiologist.length > 0
    );
  };

  const submitAnesthesiologistNotes = () => {
    const data = new FormData();
    data.append("file", fileAudio);
    if (validateAnesthesiologistNotes()) {
      const object = {
        addedBy: currentUser._id,
        edrId:
          props.history.location.state.comingFrom === "notification"
            ? reponseThroughNotification._id
            : selectedRecord._id,
        notes: requestDescription,
        anesthesiologist: anesthesiologist,
      };

      console.log("id", object);
      data.append("data", JSON.stringify(object));
      console.log("data", data);

      if (isRecording) {
        dispatch({ field: "openNotification", value: true });
        setErrorMsg("Please record voice then submit.");
      } else {
        saveAnesthesiologistCall(setOpenDialog, data, props, dispatch);
      }
    } else {
      setOpenNotification(true);
      setErrorMsg("Please Fill the fields");
    }
    setDialogForm(true);
  };

  const editAnesthesiologistNotes = (rec) => {
    console.log("rec", rec);
    setSelectedAnesthesiologistToEdit(rec);
    const url = `${audioURL}/${rec.voiceNotes}`;
    getSelectedAnesthesiologistCall(rec, url, dispatch, setOpenDialog);
  };

  const handleViewAnesthesiologistNotes = (rec) => {
    console.log("rec", rec);
    setViewRecord(true);
    setSelectedAnesthesiologistToEdit(rec);
    const url = `${audioURL}/${rec.voiceNotes}`;
    getSelectedAnesthesiologistCall(rec, url, dispatch, setOpenDialog);
  };

  const handleEditAnesthesiologistNotes = () => {
    if (anesthesiologistToEdit.addedBy._id !== currentUser._id) {
      setOpenDialog(false);
      setOpenNotification(true);
      setErrorMsg("You can only update the request added by you.");
      return;
    } else if (anesthesiologistToEdit.status === "complete") {
      setOpenDialog(false);
      setOpenNotification(true);
      setErrorMsg("Request can not be updated once completed.");
      return;
    }
    const data = new FormData();
    data.append("file", fileAudio);
    if (validateAnesthesiologistNotes()) {
      let object;
      if (!fileAudio) {
        object = {
          addedBy: currentUser._id,
          edrId:
            props.history.location.state.comingFrom === "notification"
              ? reponseThroughNotification._id
              : selectedRecord._id,
          notes: requestDescription,
          noteId: anesthesiologistToEdit._id,
          anesthesiologist: anesthesiologist,
          voiceNotes: anesthesiologistToEdit.voiceNotes,
        };
      } else {
        object = {
          addedBy: currentUser._id,
          edrId:
            props.history.location.state.comingFrom === "notification"
              ? reponseThroughNotification._id
              : selectedRecord._id,
          notes: requestDescription,
          noteId: anesthesiologistToEdit._id,
          anesthesiologist: anesthesiologist,
        };
      }

      console.log("id", object);
      data.append("data", JSON.stringify(object));
      // console.log('data', data)

      if (isRecording) {
        dispatch({ field: "openNotification", value: true });
        setErrorMsg("Please record voice then submit.");
      } else {
        editAnesthesiologistCall(setOpenDialog, data, props, dispatch);
      }
    } else {
      setOpenNotification(true);
      setErrorMsg("Please add consultation notes");
    }
    setDialogForm(true);
  };

  const validateConsultationNotes = () => {
    return (
      requestDescription !== "" &&
      consultationSpeciality !== "" &&
      consultant !== "" &&
      consultationType !== ""
    );
  };

  const submitConsultationNotes = () => {
    const data = new FormData();
    data.append("file", fileAudio);
    if (validateConsultationNotes()) {
      const object = {
        addedBy: currentUser._id,
        edrId:
          props.history.location.state.comingFrom === "notification"
            ? reponseThroughNotification._id
            : selectedRecord._id,
        notes: requestDescription,
        consultant: consultant,
        speciality: consultationSpeciality,
        subType: consultationType,
      };

      console.log("id", object);
      data.append("data", JSON.stringify(object));
      console.log("data", data);

      if (isRecording) {
        setOpenNotification(true);
        setErrorMsg("Please record voice then submit.");
      } else {
        saveConsultationNotesCall(setOpenDialog, data, props, dispatch);
      }
    } else {
      setOpenNotification(true);
      setErrorMsg("Please Fill the fields");
    }
    setDialogForm(true);
  };

  const handleViewConsultationNotes = (rec) => {
    // setViewRecord(true);
    // setSelectedConsultationToEdit(rec);
    // const url = `${audioURL}/${rec.voiceNotes}`;
    // const consultantVoiceNotes = `${audioURL}/${rec.consultantVoiceNotes}`;
    // getSelectedConsultationNoteCall(
    //   rec,
    //   url,
    //   consultantVoiceNotes,
    //   dispatch,
    //   setOpenDialog,
    //   currentUser._id
    // );
    // let pathname = "/dashboard/home/viewLaboratoryReport";
    let pathname = "/dashboard/home/"+episodeId+"/consultation/"+rec._id+"/details";
    // let labRadStatus = item.status;

    props.history.push({
      pathname: pathname,
      // state: {
      //   selectedItem: {
      //     labRadStatus,
      //     ...item,
      //     ...selectedRecord,
      //   }
      // },
    });
  };

  const editConsultationNotes = (rec) => {
    console.log("rec", rec);
    setSelectedConsultationToEdit(rec);
    const url = `${audioURL}/${rec.voiceNotes}`;
    const consultantVoiceNotes = `${audioURL}/${rec.consultantVoiceNotes}`;
    getSelectedConsultationNoteCall(
      rec,
      url,
      consultantVoiceNotes,
      dispatch,
      setOpenDialog,
      currentUser._id
    );
  };

  const handleEditConsultationNotes = () => {
    if (
      currentUser.subType.includes("Internal") ||
      currentUser.subType.includes("External")
    ) {
      if (currentUser._id !== selectedConsultationToEdit.consultant) {
        setOpenDialog(false);
        setOpenNotification(true);
        setErrorMsg(
          "You can only complete the notes which are assigned to you."
        );
        return true;
      }
    }
    if (currentUser._id !== selectedConsultationToEdit.addedBy._id) {
      setOpenDialog(false);
      setOpenNotification(true);
      setErrorMsg("You can only update the request which is added by you.");
      return true;
    }
    if (selectedConsultationToEdit.status === "complete") {
      setOpenDialog(false);
      setOpenNotification(true);
      setErrorMsg("Request can not be updated once completed");
      return true;
    }

    const data = new FormData();
    data.append("file", fileAudio);
    if (validateConsultationNotes()) {
      let object = {
        addedBy: selectedConsultationToEdit.addedBy._id,
        edrId:
          props.history.location.state.comingFrom === "notification"
            ? reponseThroughNotification._id
            : selectedRecord._id,
        noteId: selectedConsultationToEdit._id,
        notes: requestDescription,
        speciality: consultationSpeciality,
        consultant: consultant,
        subType: consultationType,
      };
      if (!fileAudio) {
        object = {
          ...object,
          voiceNotes: selectedConsultationToEdit.voiceNotes,
        };
      }
      console.log("id", object);
      data.append("data", JSON.stringify(object));
      // console.log('data', data)

      if (isRecording) {
        setOpenNotification(true);
        setErrorMsg("Please record voice then submit.");
      } else {
        editConsultationNotesCall(setOpenDialog, data, props);
      }
    } else {
      setOpenNotification(true);
      setErrorMsg("Please add consultation notes");
    }
    setDialogForm(true);
  };

  const completeConsultationRequest = () => {
    if (selectedConsultationToEdit.status === "complete") {
      setOpenDialog(false);
      setOpenNotification(true);
      setErrorMsg("Request can not be updated once completed");
      return;
    }
    if (
      currentUser.subType.includes("Internal") ||
      currentUser.subType.includes("External")
    ) {
      if (currentUser._id !== selectedConsultationToEdit.consultant) {
        setOpenDialog(false);
        setOpenNotification(true);
        setErrorMsg(
          "You can only complete the notes which are assigned to you."
        );
        return true;
      }
    }

    if (isRecordingForConsultant) {
      setOpenDialog(false);
      setOpenNotification(true);
      setErrorMsg("Please stop recording voice then submit.");
      return;
    }

    if (!consultantNotes) {
      setOpenDialog(false);
      setOpenNotification(true);
      setErrorMsg("Please add your consultation notes.");
      return;
    }
    setShowConfirmationModel(true);
  };

  const handleCompleteConsultationNotes = () => {
    const data = new FormData();
    data.append("file", fileAudioForConsultant);
    if (consultantNotes) {
      let object = {
        edrId:
          props.history.location.state.comingFrom === "notification"
            ? reponseThroughNotification._id
            : selectedRecord._id,
        _id: selectedConsultationToEdit._id,
        consultantNotes,
        completionDate: new Date(),
        status: "complete",
      };
      data.append("data", JSON.stringify(object));
      completeConsultationNote(setOpenDialog, data, props);
    } else {
      setOpenNotification(true);
      setErrorMsg("Please add consultation notes");
    }
    setDialogForm(true);
  };

  /*const onChangeSection = (e) => {
    if (e.target.value) {
      dispatch({
        field: "code",
        value: [],
      });
      dispatch({ field: e.target.name, value: e.target.value });

      axios.get(getIcd + "/" + e.target.value).then((res) => {
        if (res.data.data) {
          console.log("hello", res.data.data);
          setIcdArr(res.data.data);
        }
      });

      let codes = Object.entries(icdCodesList[0]);
      for (var x in codes) {
        let arr = codes[x];
        if (arr[0] === e.target.value) {
          console.log("codes", arr[1]);
          seticdCode(arr[1]);
        }
      }
    } else {
      dispatch({ field: e.target.name, value: e.target.value });
      dispatch({ field: "Code", value: "" });
      seticdCode("");
    }
  };

  const handleCodeSearch = (e) => {
    let currentList = [];
    let newList = [];

    if (e.target.value !== "") {
      currentList = icdArr;
      newList = currentList.filter((item) => {
        const lc = item.icd10PCSCodes.toLowerCase();
        const filter = e.target.value.toLowerCase();
        return lc.includes(filter);
      });
    } else {
      axios.get(getIcd + "/" + section).then((res) => {
        if (res.data.data) {
          console.log("hello", res.data.data);
          // const mappedArr = res.data.data.map(
          //   (e) => e.icd10PCSCodes && e.procedureCodeDescriptions
          // )
          setIcdArr(res.data.data);
        }
      });
    }
    setIcdArr(newList);
  };

  const addICDcodes = (item, e) => {
    // console.log('item', item)
    if (
      code.includes(item.procedureCodeDescriptions + ` (${item.icd10PCSCodes})`)
    ) {
      let c = [...code];
      var index = c.indexOf(
        item.procedureCodeDescriptions + ` (${item.icd10PCSCodes})`
      );
      c.splice(index, 1);
      // e.target.className = 'addCode'
      dispatch({
        field: "code",
        value: c,
      });
    } else {
      dispatch({
        field: "code",
        value: [
          ...code,
          item.procedureCodeDescriptions + ` (${item.icd10PCSCodes})`,
        ],
      });
      // e.target.className = 'addedCode'
    }
  };*/

  console.log(code);

  const handleLifeLimbclick = () => {
    let url;
    if (lifeLimbOn){
      setLifeLimbOn(false)
      url = `${updateLifeLimbEdr}/${selectedRecord._id}/off`;
    }else{
      setLifeLimbOn(true)
      url = `${updateLifeLimbEdr}/${selectedRecord._id}/on`;
    }

    axios.get(url).then((res) => {
      if (res.data.status) {
        props.history.push({
          pathname: `/dashboard/home/${selectedRecord._id}/notes`,
        });  
        props.history.go();
      }
    });


    console.log(`${url}`)
  }

  const handlePainManagement = () => {
    let newURL = "/dashboard/home/"+episodeId+"/painmanagement";

    console.log ( selectedRecord.dcdForm[0] );
    console.log ( selectedRecord.dcdForm[0].triageAssessment.length );
    console.log ( selectedRecord.dcdForm[0].pastMedicalHistory.length );
    console.log ( selectedRecord.dcdForm[0].patientDetails.length );

    if (selectedRecord) {
      props.history.push({
        pathname: newURL,
        state: {
          'lifelimbStatus': lifeLimbOn
        }
      });
    }
  }

  const handleDCDclick = () => {
    console.log(
      selectedRecord,
      "rec after handleclick"
    );
    props.history.push({
      pathname: "/dashboard/home/dcddashboard",
      state: selectedRecord
    });
  };

  const handleDischarge = () => {

    // if (dischargeInitiated){
      
    // }else{
      console.log( "handleDischarge", props.history.location.state, selectedRecord );
      
      props.history.push({
        pathname: "discharge",
        state: {
          // edrId: episodeId,
          // selectedItem: selectedRecord,
        },
      });

    // }
  };

  const handleOxygenRequest = () => {
    let oxygenData = new FormData();
    oxygenData.append("requestType", "pharmaceutical");
    oxygenData.append("requestedBy", currentUser._id);
    // oxygenData.append("currentRequester", currentRequester);
    oxygenData.append("reconciliationNotes", JSON.stringify([]));
    oxygenData.append("item", JSON.stringify([{"itemId":"655b1e5e6ebf2959188c9859","itemName":"Oxygen","issueUnit":"Unit","itemCode":"02","unitPrice":368.3,"totalPrice":368.3,"schedule":"Now","priority":"","dosage":1,"dosageType":"","duration":1,"frequency":1,"requestedQty":"1","itemType":"pharmaceutical","make_model":"","size":"","route":"","notes":{"text":"Notes Here","audio":null}}]));
    oxygenData.append("status", 'pending');
    oxygenData.append("secondStatus", 'pending');
    oxygenData.append("edrId", episodeId);
    oxygenData.append("isEDNurse", ((currentUser.staffType === "Nurses" && currentUser.subType.includes("ED Nurse"))?'yes':'no'));
    axios
      .put(addPharmacyRequest, oxygenData)//addPharmacyRequest
      .then((res) => {
        if (res.data.success) {
          window.location.reload();
        } else if (!res.data.success) {
          setOpenNotification(true);
          setErrorMsg("Error while adding the Oxygen Request");
        }
      })
      .catch((e) => {
        console.log('PharmaReq', "error after adding Oxygen Request", e);
        setOpenNotification(true);
        setErrorMsg("An error occurred while adding the Oxygen Request.");
      });
  }

  function addPharmaRequest() {
    let newURL = "/dashboard/home/"+episodeId+"/pharmacy/new";

    console.log ( selectedRecord.dcdForm[0] );
    console.log ( selectedRecord.dcdForm[0].triageAssessment.length );
    console.log ( selectedRecord.dcdForm[0].pastMedicalHistory.length );
    console.log ( selectedRecord.dcdForm[0].patientDetails.length );

    // if (selectedRecord && 
    //   selectedRecord.dcdForm[0].triageAssessment.length>0 &&
    //   selectedRecord.dcdForm[0].pastMedicalHistory.length>0 &&
    //   selectedRecord.dcdForm[0].patientDetails.length>0
    // ) {
      props.history.push({
        pathname: newURL,
        state: {
          'lifelimbStatus': lifeLimbOn
        }
      });
    // }else{
    //   setOpenNotification(true);
    //   setErrorMsg("Please complete the DCD Form First.");
    // }
  }

  function addNonPharmaRequest() {
    let newURL = "/dashboard/home/"+episodeId+"/non-pharmacy/new";

    console.log ( selectedRecord.dcdForm[0] );
    console.log ( selectedRecord.dcdForm[0].triageAssessment.length );
    console.log ( selectedRecord.dcdForm[0].pastMedicalHistory.length );
    console.log ( selectedRecord.dcdForm[0].patientDetails.length );

    // if (selectedRecord && 
    //   selectedRecord.dcdForm[0].triageAssessment.length>0 &&
    //   selectedRecord.dcdForm[0].pastMedicalHistory.length>0 &&
    //   selectedRecord.dcdForm[0].patientDetails.length>0
    // ) {
      props.history.push({
        pathname: newURL,
        state: {
          'lifelimbStatus': lifeLimbOn
        }
      });
    // }else{
    //   setOpenNotification(true);
    //   setErrorMsg("Please complete the DCD Form First.");
    // }
  }

  function addConsultationRequest() {
    let newURL = "/dashboard/home/"+episodeId+"/consultation/new";
    props.history.push({
      pathname: newURL,
      state: {
        'lifelimbStatus': lifeLimbOn
      }
    });

    /*
    console.log ( selectedRecord.dcdForm[0] );
    console.log ( selectedRecord.dcdForm[0].triageAssessment.length );
    console.log ( selectedRecord.dcdForm[0].pastMedicalHistory.length );
    console.log ( selectedRecord.dcdForm[0].patientDetails.length );

    if (selectedRecord && 
      selectedRecord.dcdForm[0].triageAssessment.length>0 &&
      selectedRecord.dcdForm[0].pastMedicalHistory.length>0 &&
      selectedRecord.dcdForm[0].patientDetails.length>0
    ) {
    */
    /*
    }else{
      setOpenNotification(true);
      setErrorMsg("Please complete the DCD Form First.");
    }
    */
  }

  function listPharmaRequest(pharmacy) {
    let newURL = `/dashboard/home/${episodeId}/${pharmacy}/listMedication`;
    props.history.push({
      pathname: newURL,
      state: {
        'lifelimbStatus': lifeLimbOn
      }
    });
  }

  const handleViewPharmacy = (obj) => {
    console.log(obj);
    props.history.push({
      pathname: "/dashboard/home/pharmanotes/add",
      state: {
        selectedItem: obj,
        comingFor: "view",
        edrId:
          props.history.location.state.comingFrom === "notification"
            ? reponseThroughNotification._id
            : selectedRecord._id,
      },
    });
  };

  const handleEditPharmacy = (obj) => {
    console.log(obj);
    if (obj.requestedBy._id !== currentUser._id) {
      setOpenDialog(false);
      setOpenNotification(true);
      setErrorMsg("You can only update the request added by you.");
      return;
    }

    if (obj.status === "in_progress") {
      setOpenDialog(false);
      setOpenNotification(true);
      setErrorMsg("Request can not updated once it is in progress");
      return;
    }
    props.history.push({
      pathname: "/dashboard/home/pharmanotes/add",
      state: {
        selectedItem: obj,
        comingFor: "edit",
        edrId:
          props.history.location.state.comingFrom === "notification"
            ? reponseThroughNotification._id
            : selectedRecord._id,
      },
    });
  };

  console.log("from reducer", props.selectedPatientDetails);

  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={patientDetails._id} />

      <div className="cPadding">
        <div className="subheader" style={{ marginLeft: "-10px" }}>
          <div>
            <img
              src={
                value === 0
                  ? AssessIcon
                  : value === 1
                  ? EDNurseIcon
                  : value === 2
                  ? EOUNurseIcon
                  : value === 3
                  ? NurseTechIcon
                  : value === 4
                  ? ConsultIcon
                  : value === 7
                  ? LabIcon
                  : value === 8
                  ? RadIcon
                  : value === 51
                  ? AnesthIcon
                  : value === 5
                  ? PharmaIcon
                  : value === 6
                  ? PharmaIcon
                  : undefined
              }
            />

            <h4>
              {/*value === 0
                ? "ED Doctor Notes"
                : value === 1
                ? "ED Nurse Notes"
                : value === 2
                ? "EOU Nurse Notes"
                : value === 3
                ? "Nurse Technician Notes"
                : value === 4
                ? "Consultation"
                : value === 7
                ? "Lab Notes"
                : value === 8
                ? "Radiology Notes"
                : value === 5
                ? "Anesthesiologist Notes"
                : value === 6
                ? "Pharma Notes"
                : undefined*/}
                {patientDetails.patientName}
            </h4>
          </div>
          
          {!matches ? (
            ""
          ) : (
            <>
              {props.location.pathname ===
              "/dashboard/home/patientlog/ED/viewED" ? (
                undefined
              ) : (
                <div style={{ marginRight: "-10px" }}>
                  
                  {
                    userCameFrom === "investigations" ?
                    (
                      <>
                        <Button
                          onClick={BacktoInvestigationActionHandler}
                          style={{
                            ...styles.stylesForButton,
                            fontSize: matches ? 12 : 8,
                          }}
                          variant="contained"
                          color="primary"
                          Error={errorMsg}
                        >Back to Page</Button>
                        &nbsp;&nbsp;
                      </>
                    ):undefined
                  } 

                  {currentUser.subType.includes("Internal") ||
                  currentUser.subType.includes("External") ||
                  currentUser.staffType === "Sensei" ? (
                    undefined
                  ) : (
                    <>
                    <Button
                      onClick={handlePainManagement}
                      style={{
                        ...styles.stylesForDCDButton,
                        fontSize: matches ? 12 : 8,
                      }}
                      variant="contained"
                      color="primary"
                      Error={errorMsg}
                    >
                      <strong>Pain Mgmt.</strong>
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      onClick={handleLifeLimbclick}
                      style={{
                        ...styles.stylesForDCDButton,
                        fontSize: matches ? 12 : 8,
                      }}
                      variant="contained"
                      color="primary"
                      Error={errorMsg}
                    >
                      <strong>Life/Limb</strong>&nbsp;&nbsp;
                      {lifeLimbOn?<span className="badge badge-success">ON</span>:<span className="badge badge-danger">OFF</span>}
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      onClick={handleDCDclick}
                      style={{
                        ...styles.stylesForDCDButton,
                        fontSize: matches ? 12 : 8,
                      }}
                      variant="contained"
                      color="primary"
                      Error={errorMsg}
                    >
                      <img
                        src={eye_icon}
                        style={{ marginRight: 10 }}
                        className="icon-view"
                      />
                      &nbsp;&nbsp;
                      <strong>DCD Form</strong>
                    </Button>
                    </>
                  )}
                  &nbsp;&nbsp;
                  <Button
                    onClick={openDCDHistoryRequest}
                    style={{
                      ...styles.stylesForButton,
                      fontSize: matches ? 12 : 8,
                    }}
                    variant="contained"
                    color="primary"
                    Error={errorMsg}
                  >
                    DCD History
                  </Button>
                </div>
              )}
            </>
          )}
        </div>

        {props.location.pathname === "/dashboard/home/patientlog/ED/viewED" ? (
          undefined
        ) : (
          <div
            className={`${"container-fluid"} ${classes.root}`}
            style={{
              marginTop: "25px",
              /*
              paddingLeft: "10px",
              paddingRight: "10px",
              */
            }}
          >
            {matches ? (
              ""
            ) : (
              <div className="row" style={{ marginTop: 10 }}>
                {props.location.pathname ===
                "/dashboard/home/patientlog/ED/viewED" ? (
                  undefined
                ) : (
                  <div style={{ marginRight: "-10px" }}>
                    <Button
                      onClick={handleDCDclick}
                      // disabled={enableForm}
                      // onClick={enableForm ? showAlertForPatientHistory : PatientHistory}
                      onClick={handleDCDclick}
                      style={{
                        ...styles.stylesForButton,
                        fontSize: 10,
                        backgroundColor: "#845DC2",
                        marginLeft: 5,
                        width: 160,
                      }}
                      variant="contained"
                      color="primary"
                      Error={errorMsg}
                    >
                      <img
                        src={eye_icon}
                        style={{ marginRight: 10 }}
                        className="icon-view"
                      />
                      DCD Form
                    </Button>
                    &nbsp;
                    <Button
                      // disabled={enableForm}
                      // onClick={enableForm ? showAlert : TriageAssessment}
                      onClick={openDCDHistoryRequest}
                      style={{
                        ...styles.stylesForButton,
                        fontSize: 10,
                        width: 165,
                      }}
                      variant="contained"
                      color="primary"
                      Error={errorMsg}
                    >
                      DCD History
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {patientDetails ? (
          <>
          <PatientDetails2
            edrId={patientDetails._id}
            showChiefComplaint={true}
            showTriageLevel={true}
            showCareStream={true}
            showDiagnosis={true} />
          
          </>
        ) : (
          undefined
        )}

        <div>
          <div
            style={{
              height: "20px",
            }}
          />
          <Tabs
            value={value}
            onChange={handleTabChange}
            textColor="primary"
            TabIndicatorProps={{ style: { background: "#12387a" } }}
            centered={false}
            variant="scrollable"
            fullWidth={true}
            scrollButtons
            scrollButtons="auto"
          >
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: value === 0 ? "#12387a" : "#3B988C",
              }}
              label="Diagnosis"
              // disabled={enableForm}
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: value === 1 ? "#12387a" : "#3B988C",
              }}
              label="ED Nurse"
              // disabled={enableForm}
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: value === 2 ? "#12387a" : "#3B988C",
              }}
              label="EOU Nurse"
              // disabled={enableForm}
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: value === 3 ? "#12387a" : "#3B988C",
              }}
              label="Nurse Technician"
              // disabled={enableForm}
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: value === 4 ? "#12387a" : "#3B988C",
              }}
              label="Consultation"
              // disabled={enableForm}
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: value === 5 ? "#12387a" : "#3B988C",
              }}
              label="Pharma"
              // disabled={enableForm}
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: value === 6 ? "#12387a" : "#3B988C",
              }}
              label="Non-Pharma"
              // disabled={enableForm}
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: value === 7 ? "#12387a" : "#3B988C",
              }}
              label="Lab"
              // disabled={enableForm}
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: value === 8 ? "#12387a" : "#3B988C",
              }}
              label="Rad"
              // disabled={enableForm}
            />
          </Tabs>

          {value === 0 ? (
            <div
              style={{ flex: 4, display: "flex", flexDirection: "column" }}
              className="container-fluid"
            >
              <div className="row">
                <div className="col-12">
                {doctorNotesArray !== 0 ? (
                  <CustomTable
                    tableData={doctorNotesArray}
                    tableDataKeys={tableDataKeysForEDDoctorNotes}
                    tableHeading={tableHeadingForEDDoctorNotes}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                    action={action2}
                  />
                ) : (
                  undefined
                )}
                </div>
              </div>

              {currentUser &&
              currentUser.staffType === "Doctor" &&
              currentUser.subType.includes("ED Doctor") ? (
                <div className="row" style={{ marginBottom: "25px" }}>
                  <div
                    className="col-md-6 col-sm-6 col-12 d-flex justify-content-start"
                    style={{
                      paddingLeft: "1px",
                      paddingRight: "1px",
                      paddingBottom: 10,
                    }}
                  >
                    <Button
                      onClick={() => handleDischarge()}
                      style={{
                        ...styles.stylesForButton,
                        width: matches ? " " : "104%",
                        height: matches ? " " : "45px",
                        backgroundColor: "#FF6F91",

                        // marginLeft: matches ? "inherit" : " -12px",
                      }}
                      variant="contained"
                      color="primary"
                      // disabled={enableForm}
                    >
                      <img className="icon-style" src={plus_icon} />
                      &nbsp;&nbsp;
                      <strong style={{ fontSize: "12px" }}>Discharge/Disposition</strong>&nbsp;
                      {dischargeInitiated?<span className="badge badge-success">Initiated</span>:undefined}
                    </Button>
                  </div>
                  <div
                    className="col-md-6 col-sm-6 col-12 d-flex justify-content-end"
                    style={{
                      paddingRight: "1px",
                      paddingBottom: 10,
                    }}
                  >
                    <Button
                      onClick={() => setOpenAddDoctorNoteDialog(true)}
                      style={{
                        ...styles.stylesForButton,
                        width: matches ? " " : "104%",
                        height: matches ? " " : "45px",
                        backgroundColor: "#2973CF",
                        marginLeft: matches ? "inherit" : " -12px",
                      }}
                      variant="contained"
                      color="primary"
                      // disabled={enableForm}
                    >
                      <img className="icon-style" src={plus_icon} />
                      &nbsp;&nbsp;
                      <strong style={{ fontSize: "12px" }}>Add Note</strong>
                    </Button>
                  </div>
                </div>
              ) : (
                undefined
              )}
            </div>
          ) : value === 1 ? (
            <div
              style={{ flex: 4, display: "flex", flexDirection: "column" }}
              className=" container-fluid"
            >
              <div className="row">
                {edNurseArray !== 0 ? (
                  <CustomTable
                    tableData={edNurseArray}
                    tableDataKeys={tableDataKeysForNurse}
                    tableHeading={tableHeadingForNurse}
                    handleEdit={
                      currentUser.staffType === "Nurses" &&
                      currentUser.subType.includes("ED Nurse")
                        ? updateStatus
                        : editEDNurse
                    }
                    handleView={handleViewEDnurse}
                    action={
                      currentUser.staffType === "Nurses" &&
                      currentUser.subType.includes("ED Nurse")
                        ? actions
                        : currentUser.staffType === "Sensei" ||
                          currentUser.staffType === "Admin"
                        ? { view: true }
                        : action
                    }
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                ) : (
                  undefined
                )}
              </div>
              {(currentUser.staffType === "Nurses" &&
                currentUser.subType.includes("ED Nurse")) ||
              currentUser.staffType === "Sensei" ||
              currentUser.staffType === "Admin" ? (
                undefined
              ) : (
                <div className="row" style={{ marginBottom: "25px" }}>
                  <div className="col-md-6 col-sm-6 col-6"></div>
                  <div
                    className="col-md-6 col-sm-6 col-12 d-flex justify-content-end"
                    style={{ paddingRight: "1px" }}
                  >
                    <Button
                      onClick={() => setOpenDialog(true)}
                      style={{
                        ...styles.stylesForButton,
                        width: matches ? " " : "104%",
                        marginLeft: matches ? "inherit" : " -12px",
                        height: matches ? " " : "45px",
                      }}
                      variant="contained"
                      color="primary"
                      // disabled={enableForm}
                    >
                      <img className="icon-style" src={plus_icon} />
                      &nbsp;&nbsp;
                      <strong style={{ fontSize: "12px" }}>Add Request</strong>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ) : value === 2 ? (
            <div
              style={{ flex: 4, display: "flex", flexDirection: "column" }}
              className="container-fluid"
            >
              <div className="row">
                {eouNurseArray !== 0 ? (
                  <CustomTable
                    tableData={eouNurseArray}
                    tableDataKeys={tableDataKeysForNurse}
                    tableHeading={tableHeadingForNurse}
                    handleEdit={
                      currentUser.staffType === "Nurses" &&
                      currentUser.subType.includes("EOU Nurse")
                        ? updateStatus
                        : editEDNurse
                    }
                    handleView={handleViewEDnurse}
                    action={
                      currentUser.staffType === "Nurses" &&
                      currentUser.subType.includes("EOU Nurse")
                        ? actions
                        : currentUser.staffType === "Sensei" ||
                          currentUser.staffType === "Admin"
                        ? { view: true }
                        : action
                    }
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                ) : (
                  undefined
                )}
              </div>
              {(currentUser.staffType === "Nurses" &&
                currentUser.subType.includes("EOU Nurse")) ||
              currentUser.staffType === "Sensei" ||
              currentUser.staffType === "Admin" ? (
                undefined
              ) : (
                <div className="row" style={{ marginBottom: "25px" }}>
                  <div className="col-md-6 col-sm-6 col-6"></div>
                  <div
                    className="col-md-6 col-sm-6 col-12 d-flex justify-content-end"
                    style={{ paddingRight: "1px" }}
                  >
                    <Button
                      onClick={() => setOpenDialog(true)}
                      style={{
                        ...styles.stylesForButton,
                        width: matches ? " " : "104%",
                        height: matches ? " " : "45px",
                        marginLeft: matches ? "inherit" : " -12px",
                      }}
                      variant="contained"
                      color="primary"
                      // disabled={enableForm}
                    >
                      <img className="icon-style" src={plus_icon} />
                      &nbsp;&nbsp;
                      <strong style={{ fontSize: "12px" }}>Add Request</strong>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ) : value === 3 ? (
            <div
              style={{
                flex: 4,
                display: "flex",
                flexDirection: "column",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              className={`container-fluid `}
            >
              <div
                className="row"
                style={{
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                {nurseTechnicianArray !== 0 ? (
                  <CustomTable
                    tableData={nurseTechnicianArray}
                    tableDataKeys={tableDataKeysForNurse}
                    tableHeading={tableHeadingForNurse}
                    handleEdit={editEDNurse}
                    handleView={handleViewEDnurse}
                    action={
                      currentUser.staffType === "Sensei" ||
                      currentUser.staffType === "Admin"
                        ? { view: true }
                        : action
                    }
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                ) : (
                  undefined
                )}
              </div>
              {currentUser.staffType === "Sensei" ||
              currentUser.staffType === "Admin" ? (
                undefined
              ) : (
                <div className="row" style={{ marginBottom: "25px" }}>
                  <div className="col-md-6 col-sm-6 col-6"></div>
                  <div
                    className="col-md-6 col-sm-6 col-12 d-flex justify-content-end"
                    style={{ paddingRight: "1px" }}
                  >
                    <Button
                      onClick={() => setOpenDialog(true)}
                      style={{
                        ...styles.stylesForButton,
                        width: matches ? " " : "104%",
                        marginLeft: matches ? "inherit" : " -12px",
                        height: matches ? " " : "45px",
                      }}
                      variant="contained"
                      color="primary"
                      // disabled={enableForm}
                    >
                      <img className="icon-style" src={plus_icon} />
                      &nbsp;&nbsp;
                      <strong style={{ fontSize: "12px" }}>Add Request</strong>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ) : value === 4 ? (
            <div
              style={{
                flex: 4,
                display: "flex",
                flexDirection: "column",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              className={`container-fluid `}
            >

              <div className="row row-5 mt-3">
                {
                  consultationNotesArray && consultationNotesArray.length > 0 ?
                  Object.entries(consultationNotesArray).map((obj, entry) => {
                    return (
                      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="card card-detail-info card-detail-small mt-2">
                          <div className="row row-5">
                            <div className="col-12 col-md-5  card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Request ID:</strong> <span className="valX">{obj[1].requestId}</span></div>
                            <div className="col-12 col-md-7  card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Date/Time:</strong> <span className="valX">{obj[1].requestDate}</span></div>
                            <div className="col-12 col-sm-9  card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Doctor Name:</strong> <span className="valX">{obj[1].consultantName}</span></div>
                            <div className="col-12 col-md-3  card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Speciality:</strong> <span className="valX text-capitalize"><strong className="line-clamp-1">{obj[1].speciality}</strong></span></div>
                            {/*
                            <div className="col-12 col-md-12 card-detail-block block-xdiv             ">
                              <strong className="lblX">Notes</strong> <span className="valX">{obj[1].notes}</span>
                              <audio
                                controlsList="nodownload"
                                style={{
                                  marginRight: 10,
                                  width: matches ? 450 : 220,
                                  marginTop: 6,
                                  outline: "none",
                                }}
                                src={obj[1].voiceNotes?`${audioURL}/${obj[1].voiceNotes.replace('\\', '/')}`:''}
                                controls="controls"
                              />
                            </div>
                            */}
                          </div>
                          <div className="card-status-action">
                            <span className={classNames(`badge`, `status-`+ ( obj[1].status.toLowerCase() === "completed"?'completed':'pending' ) )}>{obj[1].status.toLowerCase() === "decline"?'Not Available':(obj[1].status.toLowerCase() === "completed"?'Available':'Pending')}</span>
                            { 
                              obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                            }
                            <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                              handleViewConsultationNotes(obj[1]);
                            }} />
                          </div>
                        </div>
                      </div>
                    )
                  })
                  :<div className='col-12'><div className='alert alert-info'>There is no consultant request at the moment.</div></div>
                }
              </div>
              {/*
              <div
                className="row"
                style={{
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                {consultationNotesArray !== 0 ? (
                  <CustomTable
                    tableData={consultationNotesArray}
                    tableDataKeys={
                      currentUser.staffType === "Sensei"
                        ? keyForSensei
                        : tableDataKeysForConsultationNotes
                    }
                    tableHeading={
                      currentUser.staffType === "Sensei"
                        ? tableForSensei
                        : tableHeadingForConsultationNotes
                    }
                    handleEdit={editConsultationNotes}
                    handleView={handleViewConsultationNotes}
                    action={
                      currentUser.staffType === "Sensei" ||
                      currentUser.staffType === "Admin"
                        ? { view: true }
                        : action
                    }
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                ) : (
                  undefined
                )}
              </div>
              */}

              {currentUser &&
              currentUser.staffType === "Doctor" &&
              currentUser.subType.includes("ED Doctor") ? (
                <div className="row mt-5">
                  <div className="col-md-6 col-sm-6 col-6"></div>
                  <div
                    className="col-md-6 col-sm-6 col-12 d-flex justify-content-end"
                    style={{ paddingRight: "1px" }}
                  >
                    <Button
                      onClick={addConsultationRequest}
                      style={{
                        ...styles.stylesForButton,
                        width: matches ? " " : "104%",
                        marginLeft: matches ? "inherit" : " -12px",
                        height: matches ? " " : "45px",
                      }}
                      variant="contained"
                      color="primary"
                      // disabled={enableForm}
                    >
                      <img className="icon-style" src={plus_icon} />
                      &nbsp;&nbsp;
                      <strong style={{ fontSize: "12px" }}>Add Request</strong>
                    </Button>
                  </div>
                </div>
              ) : (
                undefined
              )}
            </div>
          ) : value === 51 ? (
            <div
              style={{
                flex: 4,
                display: "flex",
                flexDirection: "column",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              className={`container-fluid `}
            >
              <div
                className="row"
                style={{
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                {anesthesiologistArray !== 0 ? (
                  <CustomTable
                    tableData={anesthesiologistArray}
                    tableDataKeys={tableDataKeysForAnesthesiologist}
                    tableHeading={tableHeadingForAnesthesiologist}
                    handleEdit={editAnesthesiologistNotes}
                    handleView={handleViewAnesthesiologistNotes}
                    action={
                      (currentUser.staffType === "Doctor" &&
                        currentUser.subType.includes("ED Doctor")) ||
                      currentUser.subType.includes("External") ||
                      currentUser.subType.includes("Internal")
                        ? action
                        : { view: true }
                    }
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                ) : (
                  undefined
                )}
              </div>

              {(currentUser &&
                currentUser.staffType === "Doctor" &&
                currentUser.subType.includes("ED Doctor")) ||
              currentUser.subType.includes("Internal") ||
              currentUser.subType.includes("External") ? (
                <div className="row" style={{ marginBottom: "25px" }}>
                  <div className="col-md-6 col-sm-6 col-6"></div>
                  <div
                    className="col-md-6 col-sm-6 col-12 d-flex justify-content-end"
                    style={{ paddingRight: "1px" }}
                  >
                    <Button
                      onClick={() => setOpenDialog(true)}
                      style={{
                        ...styles.stylesForButton,
                        width: matches ? " " : "104%",
                        marginLeft: matches ? "inherit" : " -12px",
                        height: matches ? " " : "45px",
                      }}
                      variant="contained"
                      color="primary"
                      // disabled={enableForm}
                    >
                      <img className="icon-style" src={plus_icon} />
                      &nbsp;&nbsp;
                      <strong style={{ fontSize: "12px" }}>Add Request</strong>
                    </Button>
                  </div>
                </div>
              ) : (
                undefined
              )}
            </div>
          ) : value === 5 ? (
            <div
              style={{
                flex: 4,
                display: "flex",
                flexDirection: "column",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              className={`container-fluid `}
            >
              <div className="row row-5 mt-3">
                {
                  pharmacyRequestArray && pharmacyRequestArray.length > 0 ?
                  Object.entries(pharmacyRequestArray).map((obj, entry) => {
                    return (
                      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="card card-detail-info card-detail-small mt-2">
                          <div className="row row-5">
                            <div className="col-12 col-md-5  card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Request ID:</strong> <span className="valX">{obj[1].requestId}</span></div>
                            <div className="col-12 col-md-7  card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Date/Time</strong> <span className="valX">{obj[1].requestDate}</span></div>
                            <div className="col-12 col-sm-3  card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Medication</strong> <span className="valX">{obj[1].item.length}</span></div>
                            <div className="col-12 col-md-9  card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Item(s)</strong> <span className="valX text-capitalize"><strong className="line-clamp-1">{obj[1].itemNames}</strong></span></div>
                            {
                              obj[1].isVerbalRequest?<>
                              <div className="col-12 col-md-5  card-detail-block block-xdiv             "><strong className="lblX">Created by</strong> <span className="valX">{obj[1].requesterName}</span></div>
                              <div className="col-12 col-md-5  card-detail-block block-xdiv             "><strong className="lblX">Request By</strong> <span className="valX">{obj[1].requesterName2}</span></div>
                              </>:<div className="col-12 col-md-5  card-detail-block block-xdiv             "><strong className="lblX">Requester</strong> <span className="valX">{obj[1].requesterName}</span></div>
                            }
                          </div>
                          <div className="card-status-action">
                            {obj[1] && obj[1].generatedFrom === 'Discharged Request'?<span className="badge badge-info">Discharge</span>:undefined}
                            {
                              (
                                currentUser.staffType === "Nurses" &&
                                currentUser.subType.includes("ED Nurse")?
                                (
                                  obj[1].status==="completed"?
                                  (
                                    obj[1].deliveryStatus === "completed"?
                                    <span className={classNames(`badge`, `status-completed`)}>Delivered</span>:
                                    <span className={classNames(`badge`, `status-pending`)}>Pending for Delivery</span>  
                                  ):
                                  <span className={classNames(`badge`, `status-pending`)}>Pending</span>
                                ):<span className={classNames(`badge`, `status-`+obj[1].status.toLowerCase())}>{obj[1].status}</span>
                              )
                            }
                            {
                              obj[1].isVerbalRequest?
                              <i className="ml-10 zmdi zmdi-volume-up text-success"/>:undefined
                            }
                            { 
                              obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                            }
                            <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                              viewPharmacyRequest(obj[1], "pharmacy");
                            }} />
                          </div>
                        </div>
                      </div>
                    )
                  })
                  :<div className='col-12'><div className='alert alert-info'>There is no pharmacy request at the moment.</div></div>
                }
              </div>
              <div className="row mb-4">
                {/*pharmacyRequestArray !== 0 ? (
                  <CustomTable
                    tableData={pharmacyRequestArray}
                    tableDataKeys={tableDataKeysForPharma}
                    tableHeading={tableHeadingForPharma}
                    handleView={handleViewPharmacy}
                    handleEdit={handleEditPharmacy}
                    action={
                      (currentUser.staffType === "Doctor" &&
                        currentUser.subType.includes("ED Doctor")) ||
                      currentUser.subType.includes("External") ||
                      currentUser.subType.includes("Internal")
                        ? actions
                        : { view: true }
                    }
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                ) : (
                  undefined
                )*/}
              </div>
              {(currentUser.staffType === "Nurses" &&
                currentUser.subType.includes("EOU Nurse")) ||
              currentUser.staffType === "Sensei" ||
              currentUser.staffType === "Admin" ? (
                undefined
              ) : (
                <div className="row" style={{ marginBottom: "25px" }}>
                  <div
                    className="col-md-12 col-sm-12 col-12 d-flex justify-content-end"
                    style={{ paddingRight: "1px" }}
                  >
                    {
                      currentUser.staffType === "Nurses" &&
                      currentUser.subType.includes("ED Nurse")?
                      <Button
                        // disabled={true}
                        onClick={() => {listPharmaRequest('pharmacy')}}
                        style={{
                          ...styles.stylesForButton,
                          width: matches ? " " : "104%",
                          marginLeft: matches ? "inherit" : " -12px",
                          marginRight: 10,
                          height: matches ? " " : "45px",
                        }}
                        variant="contained"
                        color="secondary"
                      >
                        <img className="icon-style" src={plus_icon} />
                        &nbsp;&nbsp;
                        <strong style={{ fontSize: "12px" }}>
                          Administration
                        </strong>
                      </Button>:undefined
                    
                    }

                    <Button
                      onClick={handleOxygenRequest}
                      style={{
                        ...styles.stylesForButton,
                        width: matches ? " " : "104%",
                        marginLeft: matches ? "inherit" : " -12px",
                        marginRight: 10,
                        height: matches ? " " : "45px",
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      <img className="icon-style" src={plus_icon} />
                      &nbsp;&nbsp;
                      <strong style={{ fontSize: "12px" }}>New Oxygen Request</strong>
                    </Button>
                    
                    <Button
                      // disabled={true}
                      onClick={addPharmaRequest}
                      style={{
                        ...styles.stylesForButton,
                        width: matches ? " " : "104%",
                        marginLeft: matches ? "inherit" : " -12px",
                        height: matches ? " " : "45px",
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <img className="icon-style" src={plus_icon} />
                      &nbsp;&nbsp;
                      <strong style={{ fontSize: "12px" }}>
                        {
                          currentUser.staffType === "Nurses" &&
                          currentUser.subType.includes("ED Nurse")?"New Verbal Request":"New Pharma Request"
                        
                        }
                      </strong>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ) : value === 6 ? (
            <div
              style={{
                flex: 4,
                display: "flex",
                flexDirection: "column",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              className={`container-fluid `}
            >
              <div className="row row-5 mt-3">
                {
                  nonpharmacyRequestArray && nonpharmacyRequestArray.length > 0 ?
                  Object.entries(nonpharmacyRequestArray).map((obj, entry) => {
                    return (
                      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="card card-detail-info card-detail-small mt-2">
                          <div className="row row-5">
                            <div className="col-12 col-md-5  card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Request ID:</strong> <span className="valX">{obj[1].requestId}</span></div>
                            <div className="col-12 col-md-7  card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Date/Time</strong> <span className="valX">{obj[1].requestDate}</span></div>
                            <div className="col-12 col-sm-3  card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">No. Items</strong> <span className="valX">{obj[1].item.length}</span></div>
                            <div className="col-12 col-md-9  card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Item(s)</strong> <span className="valX text-capitalize"><strong className="line-clamp-1">{obj[1].itemNames}</strong></span></div>
                            {
                              obj[1].isVerbalRequest?<>
                              <div className="col-12 col-md-5  card-detail-block block-xdiv             "><strong className="lblX">Created by</strong> <span className="valX">{obj[1].requesterName}</span></div>
                              <div className="col-12 col-md-5  card-detail-block block-xdiv             "><strong className="lblX">Request By</strong> <span className="valX">{obj[1].requesterName2}</span></div>
                              </>:<div className="col-12 col-md-5  card-detail-block block-xdiv             "><strong className="lblX">Requester</strong> <span className="valX">{obj[1].requesterName}</span></div>
                            }
                          </div>
                          <div className="card-status-action">
                            {obj[1] && obj[1].generatedFrom === 'Discharged Request'?<span className="badge badge-info">Discharge</span>:undefined}
                            {/*
                              (
                                currentUser.staffType === "Nurses" &&
                                currentUser.subType.includes("ED Nurse")?
                                (
                                  obj[1].deliveryStatus==="completed"?
                                  <span className={classNames(`badge`, `status-completed`)}>Accepted</span>:
                                  <span className={classNames(`badge`, `status-pending`)}>Pending for Delivery</span>
                                ):<span className={classNames(`badge`, `status-`+obj[1].status.toLowerCase())}>{obj[1].status}</span>
                              )
                            */}

                            {
                              obj[1].status==="completed"?
                              (
                                obj[1].deliveryStatus === "completed"?
                                (
                                  obj[1].pending_administration === true?
                                  <span className={classNames(`badge`, `status-pending`)}>Accepted / Pending Administration</span>:
                                  <span className={classNames(`badge`, `status-completed`)}>Completed</span>
                                ):
                                <span className={classNames(`badge`, `status-pending`)}>Pending</span>  
                              ):(
                                obj[1].deliveryStatus === "completed"?
                                <span className={classNames(`badge`, `status-completed`)}>Accepted</span>:
                                <span className={classNames(`badge`, `status-pending`)}>Pending</span>  
                              )
                            }
                            {
                              obj[1].isVerbalRequest?
                              <i className="ml-10 zmdi zmdi-volume-up text-success"/>:undefined
                            }
                            { 
                              obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                            }
                            <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                              viewPharmacyRequest(obj[1], "non-pharmacy");
                            }} />
                          </div>
                        </div>
                      </div>
                    )
                  })
                  :<div className='col-12'><div className='alert alert-info'>There is no non-pharma request at the moment.</div></div>
                }
              </div>
              {(currentUser.staffType === "Nurses" &&
                currentUser.subType.includes("EOU Nurse")) ||
              currentUser.staffType === "Sensei" ||
              currentUser.staffType === "Admin" ? (
                undefined
              ) : (
                <div className="row" style={{ marginBottom: "25px" }}>
                  <div
                    className="col-md-12 col-sm-12 col-12 d-flex justify-content-end"
                    style={{ paddingRight: "1px" }}
                  >

                    {
                      currentUser.staffType === "Nurses" &&
                      currentUser.subType.includes("ED Nurse")?
                      <Button
                        // disabled={true}
                        onClick={() => {listPharmaRequest('non-pharmacy')}}
                        style={{
                          ...styles.stylesForButton,
                          width: matches ? " " : "104%",
                          marginLeft: matches ? "inherit" : " -12px",
                          marginRight: 10,
                          height: matches ? " " : "45px",
                        }}
                        variant="contained"
                        color="secondary"
                      >
                        <img className="icon-style" src={plus_icon} />
                        &nbsp;&nbsp;
                        <strong style={{ fontSize: "12px" }}>
                          Administration
                        </strong>
                      </Button>:undefined
                    
                    }

                    <Button
                      // disabled={true}
                      onClick={addNonPharmaRequest}
                      style={{
                        ...styles.stylesForButton,
                        width: matches ? " " : "104%",
                        marginLeft: matches ? "inherit" : " -12px",
                        height: matches ? " " : "45px",
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <img className="icon-style" src={plus_icon} />
                      &nbsp;&nbsp;
                      <strong style={{ fontSize: "12px" }}>
                        {
                          currentUser.staffType === "Nurses" &&
                          currentUser.subType.includes("ED Nurse")?"New Non-Pharma Verbal Request":"New Non-Pharma Request"
                        }
                      </strong>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ) : value === 7 ? (
            <div
              style={{
                flex: 4,
                display: "flex",
                flexDirection: "column",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              className={`container-fluid `}
            >
              <div className="row row-5 mt-3">
                {
                  labRequestArray !== 0 ?
                  Object.entries(labRequestArray).map((obj, entry) => {
                    return (
                      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="card card-detail-info card-detail-small mt-2">
                          <div className="row row-5">
                            <div className="col-12 col-md-5  card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Request ID:</strong> <span className="valX">{obj[1].requestId}</span></div>
                            <div className="col-12 col-md-4  card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Date/Time</strong> <span className="valX">{obj[1].requestDate}</span></div>
                            <div className="col-12 col-sm-12 card-detail-block block-xdiv"><strong className="lblX">Profile/Test</strong> <span className="valX">{obj[1].serviceName}</span></div>
                          </div>
                          <div className="card-status-action">
                            <span className={classNames(`badge`, `status-`+obj[1].statusesCodes.toLowerCase())}>{obj[1].statusesCodes}</span>
                            { 
                              obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                            }
                            <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                              viewLabReport(obj[1]);
                            }} />
                          </div>
                        </div>
                      </div>
                    )
                  })
                  :undefined
                }
              </div>


              {(currentUser.staffType === "Nurses" &&
                currentUser.subType.includes("ED Nurse")) ||
              currentUser.subType.includes("EOU Nurse") ||
              currentUser.staffType === "Sensei" ||
              currentUser.staffType === "Admin" ? (
                undefined
              ) : (
                <div className="row mt-3" style={{ marginBottom: "25px" }}>
                  <div
                    className="col-md-12 col-sm-12 col-12 d-flex justify-content-end"
                    style={{ paddingRight: "1px" }}
                  >
                    <Button
                      onClick={() => openNewLabRequest(true)}
                      style={{
                        ...styles.stylesForButton,
                        width: matches ? " " : "104%",
                        marginLeft: matches ? "inherit" : " -12px",
                        height: matches ? " " : "45px",
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <img className="icon-style" src={plus_icon} />
                      &nbsp;&nbsp;
                      <strong style={{ fontSize: "12px" }}>
                        New Lab Request
                      </strong>
                    </Button>

                    <Button
                      onClick={() => {
                        props.history.push({
                          pathname: `/dashboard/home/manage-favorites/lab`
                        });
                        // 👇 Open link in new tab programmatically
                        // window.open(`/dashboard/home/manage-favorites/lab`, '_blank', 'noreferrer');
                      }}
                      style={{
                        ...styles.stylesForButton,
                        width: matches ? " " : "104%",
                        marginLeft: matches ? "inherit" : " -12px",
                        height: matches ? " " : "45px",
                      }}
                      variant="contained"
                      color="primary"
                      className="ml-2"
                    >
                      <i className="fa fa-heart"></i>
                      &nbsp;&nbsp;
                      <strong style={{ fontSize: "12px" }}>
                        Manage Favorites
                      </strong>
                    </Button>
                  </div>
                </div>
              )}


            </div>
          ) : value === 8 ? (
            <div
              style={{
                flex: 4,
                display: "flex",
                flexDirection: "column",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              className={`container-fluid `}
            >
              
              <div className="row row-5 mt-3">
                {
                  radRequestArray !== 0 ?
                  Object.entries(radRequestArray).map((obj, entry) => {
                    return (
                      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="card card-detail-info card-detail-small mt-2">
                          <div className="row row-5">
                            <div className="col-12 col-md-5  card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Request ID:</strong> <span className="valX">{obj[1].requestId}</span></div>
                            <div className="col-12 col-md-4  card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Date/Time</strong> <span className="valX">{obj[1].requestDate}</span></div>
                            <div className="col-12 col-sm-12 card-detail-block block-xdiv"><strong className="lblX">Profile/Test</strong> <span className="valX">{obj[1].serviceName}</span></div>
                          </div>
                          <div className="card-status-action">
                            {/*
                              <span className={classNames(`badge`, `status-`+obj[1].statusesCodes.toLowerCase())}>{obj[1].statusesCodes}</span>
                            */}
                            { 
                              obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                            }
                            <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                              viewRadReport(obj[1]);
                            }} />
                          </div>
                        </div>
                      </div>
                    )
                  })
                  :undefined
                }
              </div>





                {(currentUser.staffType === "Nurses" &&
                  currentUser.subType.includes("ED Nurse")) ||
                currentUser.subType.includes("EOU Nurse") ||
                currentUser.staffType === "Sensei" ||
                currentUser.staffType === "Admin" ? (
                  undefined
                ) : (
                  <div className="row mt-3" style={{ marginBottom: "25px" }}>
                    <div
                      className="col-md-12 col-sm-12 col-12 d-flex justify-content-end"
                      style={{ paddingRight: "1px" }}
                    >
                      <Button
                        onClick={() => openNewRadRequest(true)}
                        style={{
                          ...styles.stylesForButton,
                          width: matches ? " " : "104%",
                          marginLeft: matches ? "inherit" : " -12px",
                          height: matches ? " " : "45px",
                        }}
                        variant="contained"
                        color="primary"
                      >
                        <img className="icon-style" src={plus_icon} />
                        &nbsp;&nbsp;
                        <strong style={{ fontSize: "12px" }}>
                          New Rad Request
                        </strong>
                      </Button>

                      <Button
                        onClick={() => {
                          props.history.push({
                            pathname: `/dashboard/home/manage-favorites/rad`
                          });
                        }}
                        style={{
                          ...styles.stylesForButton,
                          width: matches ? " " : "104%",
                          marginLeft: matches ? "inherit" : " -12px",
                          height: matches ? " " : "45px",
                        }}
                        variant="contained"
                        color="primary"
                        className="ml-2"
                      >
                        <i className="fa fa-heart"></i>
                        &nbsp;&nbsp;
                        <strong style={{ fontSize: "12px" }}>
                          Manage Favorites
                        </strong>
                      </Button>
                    </div>
                  </div>
                )}



            </div>
          ) : (
            undefined
          )}

          {openItemDialog ? (
            <ViewSingleRequest
              item={item}
              openItemDialog={openItemDialog}
              viewItem={viewItem}
            />
          ) : (
            undefined
          )}
        </div>

        {openAddDoctorNoteDialog ? (
          <DoctorNotesDialog
            openAddDoctorNoteDialog={openAddDoctorNoteDialog}
            viewRecord={viewRecord}
            doctorNotes={doctorNotes}
            doctorNotesAdditional={doctorNotesAdditional}
            onChangeValue={onChangeValue}
            onCodeSelected={setSelectedCodes}
            selectedCodes={selectedCodes}
            onAdditionalCodeSelected={setSelectedAdditionalCodes}
            selectedAdditionalCodes={selectedAdditionalCodes}
            isRecording={isRecording}
            section={section}
            /*
            onChangeSection={onChangeSection}
            icd={icd}
            handleCodeSearch={handleCodeSearch}
            icdArr={icdArr}
            addICDcodes={addICDcodes}
            */
            hideDialog={hideDialog}
            selectedNoteToEdit={selectedNoteToEdit}
            handleEditDoctorNotes={handleEditDoctorNotes}
            submitDoctorNotes={submitDoctorNotes}
            blobURL={blobURL}
            stop={stop}
            start={start}
            isFormSubmitted={isFormSubmitted}
            code={code}
            doctorForm={doctorForm}
          />
        ) : (
          undefined
        )}

        {
          openRadDialog ? (
            <RadRequestsDialog
              openDialog={openRadDialog} 
              hideDialog={setOpenRadDialog}
              openNotification={setOpenNotification}
              errorMessage={setErrorMsg}
              edrId={episodeId}
              selectedPatientForReducer={props.setSelectedPatientForReducer}
              />
          ) : (
            undefined
          )
        }

        {openDialog ? (
          <RequestsDialog
            openDialog={openDialog}
            viewRecord={viewRecord}
            requestDescription={requestDescription}
            dialogForm={dialogForm}
            onChangeValue={onChangeValue}
            isRecording={isRecording}
            blobURL={blobURL}
            value={value}
            edNurseSpeciality={edNurseSpeciality}
            edNurseSpecialityList={edNurseSpecialityList}
            edNurse={edNurse}
            edNurseList={edNurseList}
            consultationType={consultationType}
            consultantTypeArray={consultantTypeArray}
            consultationSpeciality={consultationSpeciality}
            consultationSpecialityList={consultationSpecialityList}
            consultant={consultant}
            consultantArray={consultantArray}
            currentUser={currentUser}
            consultantNotes={consultantNotes}
            isRecordingForConsultant={isRecordingForConsultant}
            anesthesiologist={anesthesiologist}
            anesthesiologistList={anesthesiologistList}
            hideDialog={hideDialog}
            selectedEDNurseToEdit={selectedEDNurseToEdit}
            handleEditEDNurse={handleEditEDNurse}
            submitEDNurseRequest={submitEDNurseRequest}
            completeConsultationRequest={completeConsultationRequest}
            selectedConsultationToEdit={selectedConsultationToEdit}
            handleEditConsultationNotes={handleEditConsultationNotes}
            submitConsultationNotes={submitConsultationNotes}
            anesthesiologistToEdit={anesthesiologistToEdit}
            handleEditAnesthesiologistNotes={handleEditAnesthesiologistNotes}
            submitAnesthesiologistNotes={submitAnesthesiologistNotes}
            consultantVoiceNotes={consultantVoiceNotes}
            start={start}
            stop={stop}
          />
        ) : (
          undefined
        )}

        <Dialog
          fullScreen
          aria-labelledby="form-dialog-title"
          open={openRequestDialog}
          maxWidth="xl"
          fullWidth={true}
        >
          <DialogContent style={{ backgroundColor: "#31e2aa" }}>
            <DialogTitle
              id="simple-dialog-title"
              style={{ color: "white", marginLeft: -20 }}
            >
              {value === 7
                ? "Edit Lab Test"
                : value === 8
                ? "Edit Radiology / Imaging"
                : undefined}
            </DialogTitle>
            <div className={`container-fluid ${classes.root}`}>
              <div className="row">
                <div
                  className="col-md-12 col-sm-12 col-12"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <TextField
                    // required
                    disabled={true}
                    label={
                      value === 7
                        ? "Lab Test"
                        : value === 8
                        ? "Radiology Test"
                        : undefined
                    }
                    name={"serviceName"}
                    value={serviceName}
                    className="textInputStyle"
                    variant="filled"
                    variant="filled"
                    InputProps={{
                      className: classes.multilineColor,
                      classes: {
                        input: classes.multilineColor,
                      },
                      disableUnderline: true,
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div
                  className="col-md-12 col-sm-12 col-12"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <TextField
                    required
                    select
                    // disabled={enableForm}
                    label="Priority"
                    name={"editPriority"}
                    value={editPriority}
                    error={editPriority === "" && editForm}
                    onChange={onChangeValue}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    {priorityArray.map((val) => {
                      return (
                        <MenuItem key={val.key} value={val.key}>
                          {val.value}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-12 col-sm-12 col-12"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <TextField
                    required
                    multiline
                    rows={4}
                    label="Comments/Notes"
                    name={"editComments"}
                    value={editComments}
                    error={editComments === "" && editForm}
                    onChange={onChangeValue}
                    className="textInputStyle"
                    variant="filled"
                    variant="filled"
                    InputProps={{
                      className: classes.multilineColor,
                      classes: {
                        input: classes.multilineColor,
                      },
                      disableUnderline: true,
                    }}
                  />
                </div>
              </div>

              <div
                class="row"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 5,
                  paddingRight: 5,
                  marginTop: 20,
                }}
              >
                <div style={{ marginTop: "2%", marginBottom: "2%" }}>
                  <Button
                    onClick={() => hideDialog()}
                    style={{
                      ...styles.stylesForButton,
                      backgroundColor: "white",
                      color: "grey",
                      width: matches ? " " : 145,
                    }}
                    variant="contained"
                  >
                    <strong>Cancel</strong>
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "2%",
                    marginBottom: "2%",
                  }}
                >
                  <Button
                    style={{
                      ...styles.stylesForSubmitButton,
                    }}
                    onClick={
                      value === 7
                        ? handleEditLabRequest
                        : value === 8
                        ? handleEditRadRequest
                        : undefined
                    }
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          fullScreen
          aria-labelledby="form-dialog-title"
          open={openUpdateStatusModal}
          maxWidth="xl"
          fullWidth={true}
        >
          <DialogContent style={{ backgroundColor: "rgb(19 213 159)" }}>
            <DialogTitle
              id="simple-dialog-title"
              style={{ color: "white", marginLeft: -9 }}
            >
              Update Status
            </DialogTitle>
            <div className="container-fluid">
              <div className="row">
                <div
                  className={`col-12 ${classes.root}`}
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <TextField
                    required
                    select
                    label="Status"
                    name={"requestStatus"}
                    value={requestStatus}
                    onChange={onChangeValue}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    {statusArray.map((val) => {
                      return (
                        <MenuItem key={val.key} value={val.value}>
                          {val.key}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div
                class="row"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 5,
                  paddingRight: 5,
                  marginTop: 20,
                }}
              >
                <div>
                  <Button
                    onClick={() => hideDialog()}
                    style={{
                      ...styles.stylesForButton,
                      backgroundColor: "white",
                      color: "grey",
                      width: matches ? " " : 145,
                    }}
                    variant="contained"
                  >
                    <strong>Cancel</strong>
                  </Button>
                </div>
                <div>
                  {value === 1 || value === 2 || value === 3 ? (
                    <Button
                      disabled={
                        requestStatus.length === 0 ||
                        requestStatus === "pending"
                          ? true
                          : false
                      }
                      style={{
                        ...styles.stylesForSubmitButton,
                      }}
                      onClick={
                        selectedEDNurseToEdit != ""
                          ? handleEditEDNurse
                          : submitEDNurseRequest
                      }
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  ) : (
                    undefined
                  )}
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <div
          className="container-fluid"
          style={{ marginBottom: "25px", marginTop: "25px" }}
        >
          <div className="row">
            <img
              onClick={() => props.history.goBack()}
              src={Back}
              style={{ width: 45, height: 35, cursor: "pointer" }}
            />
          </div>
        </div>

        <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />
      </div>

      {showConfirmationModel ? (
        <ConfirmationDialog
          dialogMessage={
            "You will not be able to update the request after completing it. Are you sure want to complete it?"
          }
          dialogHeading={"Complete Request"}
          onClickDone={handleCompleteConsultationNotes}
          onClickCancel={setShowConfirmationModel}
        />
      ) : (
        undefined
      )}
    </div>
  );
}
const mapStateToProps = ({ CheckingReducer, PatientReducer }) => {
  const { count } = CheckingReducer;
  const { selectedPatientDetails } = PatientReducer;

  return { count, selectedPatientDetails };
};
export default connect(mapStateToProps, {
  funForReducer,
  setSelectedPatientForReducer,
  setPatientDetailsForReducer,
})(EpisodeInfoDetailPage);
